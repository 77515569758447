@import '../styles/fonts';

:root {
  --antd-wave-shadow-color: #436ACE;
}

body {
  font-family: inherit;
  font-size: inherit;
  font-feature-settings: inherit;
}

.ant-menu {
  background-color: var(--app-dark-background);
  border-bottom: none;
}

.ant-modal-content{
  margin: 40px 0;
}

// Navigation Menu
li.ant-menu-item.ant-menu-item-only-child {

  &::after {
    border-bottom: none !important;
    transition: none;
  }

  &:hover {

    &::after {
      border-bottom: none !important;
    }
  }

  a {
    display: block;
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-menu);
    text-align: center;
    color: var(--app-white);
    height: 48px;
    padding: 0 20px;
    width: 128px;
    border-bottom: none;

    &:hover {
      text-align: center;
      color: var(--app-white);
      font-weight: var(--font-weight-title);
      border-bottom: 2px solid var(--app-white);
      opacity: 1;
    }

    &.active {
      height: 48px;
      padding: 0 6px;
      font-weight: var(--font-weight-title);
      border-bottom: 2px solid var(--app-white);
      opacity: 1;
    }
  }

  &.ant-menu-item-selected {

    a {
      color: var(--app-purple);
      font-weight: bold;
    }
  }
}

li.ant-menu-overflowed-submenu {
  span {
    color: var(--app-white);
  }
}

.ant-menu-submenu-popup > .ant-menu {
  background-color: var(--app-dark-background);
}

.ant-select {
  text-align: left;
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-light);

  &.ant-select-single.ant-select-show-arrow.prefixIcon {

    .ant-select-arrow {
      left: 11px
    }

    span.ant-select-selection-item {
      padding-right: 0;
      padding-left: 18px;
    }
  }
}

.ant-select-item {
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-light);

  &.ant-select-item-option-grouped, &.ant-select-item-option-selected {
    padding: 5px 12px;
  }
}

.ant-select-selection-item, .ant-select-item-option-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .anticon-exclamation-circle {
    color: #FFA52B;
  }
}

// ant - table
.ant-table-wrapper {

  .ant-table.ant-table-small.ant-table-fixed-header {

    .ant-table-container {

      .ant-table-header {
        min-height: 36px;
      }
    }
  }
}

.ant-tooltip {
  font-size: var(--font-size-text);

  .ant-tooltip-inner {
    color: var(--plain-text) !important;
  }

  &.wide-tooltip {
    & .ant-tooltip-content {
      width: 500px;

      & .ant-tooltip-inner {
        width: 100%;

        & .tooltip-wrapper {
          width: 100%;
          word-wrap: break-word
        }
      }
    }
  }

  &.unset-width {
    & .ant-tooltip-content {
      & .ant-tooltip-inner {
        width: unset;
      }
    }
  }
}

.ant-select-dropdown.query-endpoint-autocomplete {
  .ant-select-item.ant-select-item-group {

    .any-endpoint-option {
      padding-bottom: 8px;
    }
  }
}

// Tabs
.SingleCallDetails,
.FindingCallDetails,
.discovery-table {
  .ant-tabs.ant-tabs-top.ant-tabs-card {
    flex: 1;
    background: #fff;

    .ant-tabs-content-holder {
      overflow: hidden;

      .ant-tabs-content {
        overflow: hidden;
        height: 100%;

        .ant-tabs-tabpane {
          overflow: hidden;
          height: 100%;

          .ant-list {
            height: 100%;
          }
        }
      }
    }

    .ant-tabs-nav {
      margin-bottom: 0;

      .ant-tabs-nav-wrap {
        background: #fff;
        box-shadow: 0 2px 20px 0 rgb(231, 235, 245);
        z-index: 10;
        height: 48px;

        .ant-tabs-nav-list {
          .ant-tabs-tab {
            background: #fff !important;
            border: none;
            border-top: 2px solid var(--app-white);
            font-weight: var(--font-weight-title);
            font-size: var(--font-size-title);
            text-align: center;

            .ant-tabs-tab-btn {
              color: var(--plain-text);
              text-transform: capitalize;
              font-weight: var(--font-weight-medium);
              opacity: 0.6;
            }

            &-active {
              //background-color: var(--selected-light-purple) !important;
              border-bottom: 2px solid var(--app-grayish);

              .ant-tabs-tab-btn {
                opacity: 1;
                font-weight: var(--font-weight-title);
              }
            }
          }
        }
      }
    }

    .ant-tabs.ant-tabs-top.ant-tabs-card {
      background: #fff !important;
      border-top: 2px solid var(--app-white);
    }
  }
}


.ant-spin {
  .ant-spin-text {
    color: var(--app-purple);
  }
}

// date-time picker
.ant-picker {
  .ant-picker-input {
    input {
      color: var(--plain-text);
    }
  }
}

.ant-picker-dropdown {
  .ant-picker-panel-container {
    .ant-picker-panels {
      .ant-picker-header {
        color: var(--app-purple);

        .ant-picker-header-view {
          @include control-label;

          button {
            text-transform: uppercase;
          }
        }
      }

      .ant-picker-body {
        .ant-picker-content {

          tbody {

            tr {
              td {
                color: var(--plain-text);
              }
            }
          }
        }
      }

      .ant-picker-content {
        .ant-picker-time-panel-column {
          .ant-picker-time-panel-cell-inner {
            color: var(--plain-text);
          }
        }
      }
    }
  }
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item svg {
  height: 1rem;
  width: 1rem;
}

.ant-message {
  top: initial;
  bottom: 10px;

  .ant-message-notice {
    &.warning {
      .ant-message-notice-content {
        background: #FFA52B;
      }
    }

    &.error {
      .ant-message-notice-content {
        background: #F66060;
      }
    }

    .ant-message-notice-content {
      padding: 10px 16px;
      background: var(--app-purple);
    }

    .ant-message-notice.move-up-enter.move-up-enter-active {
      -webkit-animation-name: antMoveUpIn !important;
      animation-name: antMoveUpIn !important;
    }

    .ant-message-notice.move-up-leave.move-up-leave-active {
      -webkit-animation-name: antMoveDownOut !important;
      animation-name: antMoveDownOut !important;
    }

    .ant-message-success {
      color: var(--app-white);
    }
  }
}

// Radio buttons
.ant-radio-checked {

  .ant-radio-inner {
    border-color: var(--app-grayish);

    &::after {
      background-color: var(--app-grayish);
    }
  }

  .ant-radio-input:focus {
    & + .ant-radio-inner {
      border-color: var(--app-grayish);
    }
  }
}

.ant-modal {

  .ant-modal-content {
    
    .ant-modal-header {
      min-height: 60px;
      padding-top: 20px;
      background-color: var(--app-gray-background);
    }

    .ant-modal-close-x path {
      fill: var(--tag-color);
    }

    .ant-modal-footer {
      border-top: 0;
      padding: 4px 24px 16px 24px;

      .custom-footer {

        .ant-btn {
          border-radius: 3px;
        }
      }
    }
  }
}

// Error message
.ant-message-notice {
  &-content {
    background: none;
    color: var(--app-white);
  }
}

.ant-tag {
  font-size: var(--font-size-text);
  font-weight: var(--font-weight-light);
}

.ant-input {
  font-size: var(--font-size-text);
  height: 36px;
}

.ant-form {

  .ant-form-item-label {
    padding: 0 !important;

    label {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-semibold);
      text-transform: uppercase;
      color: var(--app-grayish);
      opacity: 0.7;
    }
  }

  .ant-form-item-has-error .ant-input {
    border: 1px solid var(--app-purple-shade-one);
    box-shadow: none;
  }
}

.ant-switch-small {
  max-width: 30px;
  height: 10px;

  &:focus, &:active {
    box-shadow: none;
  }

  .ant-switch-handle {
    height: 16px;
    width: 16px;
    top: -3px;
    left: 0;
    border: 1px solid var(--app-purple);
    border-radius: 50%;
  }
}

// Steps (Wizard)
.ant-steps-item {

  & > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    font-size: var(--font-size-text);
    font-weight: var(--font-weight-medium);

    &::after {
      background: linear-gradient(to right, var(--app-purple) 50%, rgba(255, 255, 255, 0) 0%) repeat-x;
      background-size: 8px 1px;
      opacity: 0.4;
    }
  }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: var(--app-purple);
  border-color: var(--app-purple);
  font-family: var(--font-family-plain-text);
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
  background: none;
  border-color: var(--app-grayish);
  font-family: var(--font-family-plain-text);
  opacity: 0.5;

  .ant-steps-icon {
    color: var(--app-grayish);
  }
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
  background: rgba(67, 106, 206, 0.2);
  border-color: var(--app-purple);
  font-family: var(--font-family-plain-text);

  .ant-steps-icon {
    color: var(--app-purple);
  }
}
