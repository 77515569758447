@import "../../../../styles/mixins";
@import "../discoveryDashboardSharedStyles";

div.DiscoveryServiceStatsWidget.DiscoveryServiceStatsWidget {
  @include ddWidgetsBaseStyles;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

  .DiscoveryStat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 42%;
  }

  .dss-title {
    @include title2;
    margin-inline-end: 10px;
  }

  .dss-count {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dss-count-title {
    @include title;
  }

  .dss-new-table-title {
    @include new-table-title;
  }

  .dss-divider {
    height: 32px;
    color: var(--switch-background);
  }
}
