.query-container {
  padding: 0 40px 24px 40px;
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto auto 1fr;

  .query-builder-wrapper {
    overflow: hidden;
    position: relative;
    min-height: 215px;

    .query-builder-header {
      display: flex;
      align-items: baseline;
      padding: 24px 0 0 0;
      justify-content: flex-end;

      .box-title {
        padding-bottom: 4px;
      }

      .top-controls {
        display: flex;
        align-items: center;

        .control {
          display: flex;
          align-items: center;

          & + .control {
            padding-left: 24px;
          }
        }
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
  }

  .query-results-summary {
    padding-top: 24px;
  }

  .query-results-container {
    width: 100%;
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    position: relative;
  }
}
