@import '../../../../../styles/mixins';


.alert-multi-select {
  position: relative;
  margin-top: -5px;

  .ams-label {
    @include control-label-top;
  }
}
