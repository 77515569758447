.ant-tooltip {
  max-width: 500px;

  &.double-width {
    max-width: 1000px;
  }

  .ant-tooltip-content {

    .ant-tooltip-inner {
      max-height: 100vh;
      overflow-y: auto;
      background-color: var(--app-white);
      color: var(--plain-text);
      border: 1px solid var(--app-purple-shade-two);
      box-shadow: 0 10px 20px rgba(117, 130, 166, 0.25);
      font-weight: var(--font-weight-title);
      cursor: default;
      display: flex;
      flex-wrap: nowrap;
      user-select: none;
      width: 100%;
    }

    .ant-tooltip-arrow {

      .ant-tooltip-arrow-content {
        background-color: var(--app-white);
        border-radius: 2px;
        height: 8px;
        width: 8px;
      }
    }
  }

  &.ant-tooltip-placement-top .ant-tooltip-arrow {
    bottom: 4px;
  }

  &.ant-tooltip-placement-bottom .ant-tooltip-arrow {
    top: 4px;
  }

  &.ant-tooltip-placement-right .ant-tooltip-arrow {
    left: 4px;
  }

  &.ant-tooltip-placement-left .ant-tooltip-arrow {
    right: 4px;
  }
}

.UiTooltip {

  .tooltip-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .tooltip-content {
    width: 100%;
    height: 100%;
    padding-bottom: 8px;
    overflow: auto;
    overflow-wrap: normal;
    white-space: break-spaces;
  }

  .tooltip-footer {
    position: relative;
    bottom: 28px;
    display: flex;
    justify-content: flex-end;

    .ant-btn {

      span:not(:first-child) {
        color: var(--plain-text);
        text-decoration: none;
      }

      &:last-child {

        span:not(:first-child) {
          margin-inline-end: 0;
        }
      }
    }
  }
}
