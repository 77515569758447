.page-header-container {

  .discovery-header-first-line {
    display: flex;
    justify-content: space-between;
    align-items: start;

    .services-header {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      .hide-endpoint-select {
        opacity: 0;
      }

      .visible-endpoint-select {
        opacity: 1;
      }
    }

    .top-controls {
      display: flex;
      align-items: center;
      gap: 20px;

      .services-control {
        display: flex;

        .search-endpoints {
          font-size: 14px;
          font-weight: 500;
          margin-left: 7.5px;
        }
      }

      .services-label {
        margin-top: 8px;
      }

      .time-range-discovery {

        .control-label {
          width: 100px;
        }
      }
    }
  }
}

.suppress-label-modal {

  .suppress-label-modal-text {
    margin-right: 10px;
  }

  .ant-select {
    width: 120px;
  }
}
