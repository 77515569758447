
.ScanExtraDetails{
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  border-top: 1px solid var(--app-purple-shade-three);
  padding: 16px 16px 16px 32px;

  div {
    margin-block-end: 12px;
  }
}