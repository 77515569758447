.expand-button-container {
  position: absolute;
  z-index: 200;
  transition: transform 0.6s;
  cursor: pointer;
  width: 22px;
  height: 22px;
  bottom: -14px;
  left: calc(50% - 11px);
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    transform: rotate(180deg);
  }
}