@import "../../../../../styles/fonts";
@import "../../discoveryDashboardSharedStyles";
@import "../pieWidgetsSharedStyles";

.DiscoveryEndpointsByApiTypeWidget {
    @include ddWidgetsBaseStyles;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include pieWidgetBase;

    .ant-radio-wrapper {
        font-size: var(--font-size-small);
    }
}
