@import '../../../../../styles/fonts';

.AddScanFormModal {

  .test-suite {
      @include text();
      color: var(--plain-text);
      margin-top: 24px;
  }

  .create-auth-config {
      @include text-regular();
      color: var(--plain-text);

    .italic-text {
        font-style: italic;
      }

    .ant-btn {
      margin: 0 3px;

      span {
        text-transform: lowercase;
      }
    }
  }

  .select-auth-config{
      margin-bottom: 0;
  }

  .oas-upload {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;

    .ant-upload-list {
      width: 100%;
      background-color: var(--settings-bg);

      .ant-select-arrow .anticon-down {
        display: none;
      }

      .ant-select-selector {
        border: unset;
        background: unset;
        color: var(--plain-text);
      }
    }
  }

  .oas-upload-message {
    @include text-regular();
    margin-top: 2px;

    .oas-upload-success-message-part-i,
    .oas-upload-success-icon {
      color: var(--bright-greenish-1);
    }

    .oas-upload-error-message,
    .oas-upload-error-icon {
      color: var(--critical-red);
    }
  }
}

.target-url-dropdown-footer {

  .target-url-dropdown-footer-divider {
    margin: 8px 0;
  }

  .target-url-dropdown-footer-input {
    padding: 0 8px 4px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }
}