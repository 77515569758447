@import '../../../../../styles/mixins';


.endpoint-multi-select {
  position: relative;
  margin-top: -5px;

  .ems-label {
    @include control-label-top;
  }

  .ant-select {
    height: inherit !important;
  }
}
