
.discovery-master-detail {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .endpoint-header {
    color: var(--plain-text);
    display: flex;
    flex-direction: column;
    padding: 18px 24px 2px 18px;
    width: 100%;
    overflow: hidden;
  }

  .top-bar-container {
    display: flex;
    position: relative;
    padding: 0px 24px 0 18px;

    .alerts-carousel-container {
      margin-left: 12px;
      overflow: hidden;
      flex: 1 1 45%;
    }

    .endpoint-data-container {
      display: flex;
      flex-direction: column;
      flex: 1 1 55%;
    }
  }

  .discovery-table-container {
    max-width: 100%;
    margin-top: 12px;
    flex: 1 1;
    overflow: hidden;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
  }
}
