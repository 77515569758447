@import "~@ag-grid-community/core/dist/styles/ag-grid.scss";
@import "~@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import "./colors";
@import './fonts';

.ag-theme-alpine {
  @include ag-theme-alpine((
          alpine-active-color: #436ACE,
          foreground-color: var(--plain-text),
          header-height: 36px,
          header-background-color: white,
          font-family: var(--font-family-plain-text),
          font-size: var(--font-size-text) !important,
          border-radius: 3px,
          border-color: var(--app-purple-shade-two),
          selected-tab-underline-width: 0,
          subheader-toolbar-background-color: null,
  ));

  --ag-selected-row-background-color: var(--grey-background);

  .ag-tab {
    border-top: 2px solid transparent;

    &.ag-tab-selected {
      border-top: 2px solid var(--app-purple);
    }
  }

  .ag-root-wrapper.ag-layout-normal {
    border: none;

    .ag-header-cell-label {
      @include control-label;
    }

    .ag-row {
      background-color: #fff;

      &:hover {
        background-color: rgba(191, 201, 226, 0.15);
      }

      &.disabled-row {
        background-color: var(--secondary-bg-color);
        opacity: 0.5;
      }

      &.deprecated-row {
        background-color: var(--highlight);
        opacity: 0.5;
      }

      .ag-cell-focus, .ag-cell-no-focus {
        border: none !important;
      }

      .ag-cell {
        height: 100%;
        line-height: 1.4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding-right: 5px;

        .ag-cell-wrapper {
          max-width: 100%;
          align-items: center;
        }
      }
    }

    .ag-details-row {
      padding: 0;

      .ag-row {
        background-color: var(--app-secondary-background) !important;
      }

      .ag-header-row, .ag-row {
        background-color: var(--app-white);
      }
    }
  }


  .ag-cell-wrapper {
    .ag-group-expanded, .ag-group-contracted {
      height: 100% !important;
    }
  }

  .ag-tooltip {
    background-color: #fff;
    color: var(--plain-text);
    border-radius: 3px;
    padding: 12px;
    border-width: 1px;
    border-style: solid;
    border-color: #d3d3d3;
    box-shadow: 2px 2px 10px 15px rgba(0, 0, 0, 0.25);

    &:before {
      content: '';
      position: absolute;
      bottom: calc(100% + 1px);
      left: 50%;
      margin-left: -6px;
      width: 0;
      height: 0;
      border-bottom: solid 6px lightgrey;
      border-left: solid 6px transparent;
      border-right: solid 6px transparent;
    }

  }


  .ag-menu.ag-ltr.ag-popup-child {
    background-color: var(--app-white);
    box-shadow: 3px 3px 16px 2px rgba(0, 30, 40, 0.25);
  }

  .ag-loading {
    //display: none;
  }


  .discovery-table {
    .ag-cell {
      line-height: 40px !important;
    }
  }

  // time filter date picker
  .ag-popup {

    .ag-tabs.ag-menu.ag-focus-managed.ag-popup-child {
      overflow: visible;
    }


    .ag-filter-apply-panel {
      display: flex;
      justify-content: space-between;

      & button {
        cursor: pointer;
        transition: all 0.2s;
        font-weight: normal;
        border-radius: 2px;
        font-size: 14px;

        &:first-of-type {
          border: none;

          &:hover {
            background: unset;

          }
        }

        &:nth-of-type(2) {
          background: var(--app-purple);
          color: var(--app-white);

          &:hover {
            background: var(--btn-primary-hover);
          }
        }
      }
    }
  }

  .ag-header-icon.ag-header-label-icon.ag-filter-icon {
    position: absolute;
    right: 18px;
  }

  .ag-header-icon.ag-header-cell-menu-button {
    z-index: 100;
  }

  .ag-header-cell {
    //position: relative!important;

    .ag-header-cell-resize::after {
      left: calc(50% - 1px);
      width: 5px;
      height: 10px;
      top: calc(50% - 5px);
      background-color: #DCDCDC;
    }

    &:focus {
      outline: none;
      border: none;
    }

    .header-cell-container {
      &:focus {
        outline: none;
        border: none;
      }
    }
  }

  .custom-tooltip {
    position: absolute;
    white-space: nowrap;
    padding: 4px 8px !important;
  }

  .ag-tooltip, .tooltip-inner {
    box-shadow: 0 10px 20px rgba(117, 130, 166, 0.25) !important;
    border: 1px solid #436ace66;
    border-radius: 2px;
    padding: 4px 8px !important;
    background: var(--app-white);
  }
}

.query-results-table {
  .ag-root-wrapper.ag-layout-normal {
    .ag-cell {
      line-height: 70px;
    }
  }
}
