@import '../../../../styles/fonts.scss';
@import '../../../../styles/colors.scss';
@import './findingDetailsContainer';

.FindingDetails {
  height: 100%;
  display: flex;
  flex-direction: column;

  .finding-details-header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-block-end: 24px;

    .finding-details-name {
      @include title;
    }
  }

  .finding-details-layout {
    display: flex;
    gap: 10px;
    flex-grow: 1;

    .finding-details-layout-left,
    .finding-details-layout-right {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .finding-details-layout-left {
      width: 60%;
    }

    .finding-details-layout-right {
      width: 40%;
    }
  }

  .finding-details-container {
    @include findingDetailsContainer;
  }

  a {
    @include link;
  }
}
