.ui-input-container {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.ui-input-error {
  position: absolute;
  right: 0;
  color: var(--red);
}

.ant-input, .ant-input-number-input-wrap {
  height: 36px;
  border: 1px solid var(--app-purple-shade-two);

  &:hover, &:focus {
    border: 1px solid var(--app-purple-shade-two) !important;
  }
}

.ant-input {
  color: var(--plain-text);
}
