@import '../../styles/mixins';

.EmptyStateList {
    position: relative;
    height: 100%;
    z-index: 3;
    border-radius: 3px;

    .message {
        @include headline2();
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;
        opacity: 1;

        .text {
            margin-top: 50px;
            width: 350px;
            text-align: center;
            color: var(--app-purple);
            font-weight: var(--font-weight-title);
            size: var(--font-size-title);
        }
    }

    .arrow-image {
        position: absolute;
        height: 45%;
        width: calc(50% - 160px);
        right: 40px;
        top: 20px;
    }
}

.blue-background {
    background: var(--app-purple-shade-four);
}
