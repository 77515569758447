.multi-select {

  .multi-select-tags {
    height: auto;

    .ant-select-selector {
      min-height: 100%;

      .ant-select-selection-item {
        background: #C8F0C7;
      }
    }
  }
}
