@import '../../styles/fonts';

.UiChartLegend {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  width: 100%;
  padding: 0;
  margin-left: 16px;

  .legend-item {
    display: flex;
    align-items: center;
    gap: 6px;
    list-style-type: none;

    .legend-item-text {
      font-size: 11px;
      overflow: hidden;
    }

    &-color {
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background-color: inherit;
    }

    &-text {
      @include text-regular;
      display: flex;
      justify-content: space-between;
      width: 100%;
      color: #565B75;

      &.active {
        font-weight: var(--font-weight-title);
      }

      &.inactive {
        opacity: 0.6;
      }

      .legend-item-name {
        padding-inline-end: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}