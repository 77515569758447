.RiskTooltip {
  display: flex;
  flex-direction: column;
  width: 140px;
  padding: 8px;
  gap: 16px;

  .risk-tooltip-row {
    display: grid;
    grid-template-columns: 3fr 2fr;
    width: 100%;
    font-weight: var(--font-weight-title);
    font-size: var(--font-size-text);
    color: var(--plain-text);

    .dot-container {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 75px;

      .dot {
        border-radius: 50%;
        width: 4px;
        height: 4px;

        &.info-dot {
          background: var(--risk-info-color);
        }

        &.low-dot {
          background: var(--risk-low-color);
        }

        &.medium-dot {
          background: var(--risk-medium-color);
        }

        &.high-dot {
          background: var(--risk-high-color);
        }

        &.critical-dot {
          background: var(--risk-critical-color);
        }
      }
    }
  }
}
