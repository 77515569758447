@import '../../styles/fonts';

.ui-title {
  color: var(--plain-text);

  .x-large {
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-title);
  }

  .large {
    @include headline1;
  }

  .medium {
    @include headline2;
    color: var(--plain-text);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-title);
  }

  .small {
    font-size: var(--font-size-text);
    opacity: 0.8;
    font-variant: small-caps;
    text-transform: uppercase;
  }

  .title-icon {
    padding-left: 12px;
  }
}
