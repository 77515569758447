@import '../../../../styles/fonts';

$tail-head-position: 75px;

.ant-timeline {

  .ant-timeline-item {
    height: 40px;
    padding-bottom: 0;
    margin-bottom: 6px;
    color: var(--plain-text);
    display: flex;
    font-size: var(--font-size-text);
    align-items: center;
    border-left: 2px solid transparent;

    &:not(.timeline-edge):hover {
      background-color: var(--grey-background-share-two);
    }

    &:hover,
    &.selected,
    &.checked {
      &.ant-timeline-item-left .ant-timeline-item-content .custom-content {
        .timeline-checkbox {
          * {
            display: block;
          }
        }
      }
    }

    &.selected {
      background-color: var(--app-purple-shade-three);
      border-left: 2px solid var(--app-purple);
      color: var(--app-purple);

      .ant-timeline-item-label {
        font-weight: var(--font-weight-medium);
      }

      .content-name {
        font-weight: var(--font-weight-semibold) !important;
        color: var(--plain-text);
      }
    }

    .ant-timeline-item-label {
      position: static;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 85px;
      display: flex;
      padding-left: 6px;
      font-size: var(--font-size-text);
      font-weight: var(--font-weight-light);
    }

    .ant-timeline-item-tail {
      top: 24px;
      height: calc(100% - 2px);
      border: 1px dashed var(--app-purple);
      left: $tail-head-position;
      z-index: 1; // so that background wont go over dashed tail
    }

    .ant-timeline-item-head {
      left: $tail-head-position;

      &.ant-timeline-item-head-blue {
        border: 1px solid var(--app-purple);
      }
    }

    &.ant-timeline-item-left {

      .ant-timeline-item-content {
        position: static;
        left: 75px;
        width: calc(100% - 16px);
        overflow: hidden;

        .custom-content {
          display: flex;
          align-items: center;

          .timeline-checkbox {
            align-self: flex-start;
            height: 16px;
            min-width: 16px;
            padding-top: 1px;

            .ant-checkbox {
              top: 0;
            }

            * {
              display: none;
            }
          }

          .marker {
            width: 55px;
            text-align: center;

            .api-tag {
              width: 55px;
              text-align: center;
            }
          }

          .content-name {
            flex: 1 1;
            user-select: none;
            cursor: pointer;
            font-size: var(--font-size-text);
            font-weight: var(--font-weight-medium);
          }

          .http-status-code {
            @include text-regular;
            padding-inline: 24px;
            color: var(--text-dark-theme);
          }
        }
      }

      &.non-query {
        .ant-timeline-item-head.ant-timeline-item-head-blue {
          background-color: #d4d4d9;
          border: 1px solid #9b9ba0;
        }
      }
    }

    &.ant-timeline-item-last {
      .ant-timeline-item-content {
        min-height: auto;
      }
    }

    &.timeline-edge .ant-timeline-item-head.ant-timeline-item-head-blue {
      border: 0;
      border-radius: 0;
      height: 3px;
      background: var(--app-purple);
    }

    &.custom-date {
      height: 25px;
      margin: 8px 0;

      .ant-timeline-item-content {
        z-index: 2;

        .next-day {
          display: inline-block;
          background: var(--light-grey);
          padding: 5px 15px;
          font-weight: normal;
        }
      }

      &:hover {
        background-color: inherit;
      }
    }

    &-content {
      .api-tag-container {
        justify-content: center;
      }
    }
  }
}
