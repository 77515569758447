
.query-conditions-container {
  display: flex;

  .condition-element {
    display: flex;
    align-items: center;

    &:before {
      display: block;
      font-size: 10px;
      content: 'And';
      padding: 0 12px;
      margin-right: 15px;
    }

    .condition-controls {
      display: flex;
      align-items: center;
      position: relative;

      .remove-icon {
        position: absolute;
        top: 8px;
        left: -25px;
      }

      .condition-control:not(:first-of-type) {
        padding-left: 10px;
      }
    }

    &:first-child {
      &:before {
        width: 37px;
        visibility: hidden;
      }
    }
  }
}
