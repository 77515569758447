.data-source-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
}

.between-card {
    margin-bottom: 24px;

    &:hover {
        cursor: pointer;
    }
}
