@import '../../styles/fonts.scss';

.UserBadge {

  .email-address {
    @include buttonText();
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-inline-start: 8px;
  }
}