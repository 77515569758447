@import "../../../../../styles/fonts";

.AlertFilterItem {
  height: 48px;
  padding: 0 16px;
  max-width: 280px;
  text-align: center;
  cursor: pointer;
  font-size: var(--font-size-title);
  font-weight: var(--font-weight-title);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .draggable-icon-box, .remove-icon-box {
    width: 8px;
    opacity: 0;

    &.non-interactive {
      visibility: hidden;
    }

    path {
      fill: var(--plain-text);
    }
  }

  &:hover {
    color: var(--app-purple);
    border-bottom: 2px solid var(--app-dark-background);

    .remove-icon-box, .draggable-icon-box {
      opacity: 1;
    }
  }

  &.selected {
    background-color: #60699933;
    border-bottom: 2px solid var(--app-dark-background);

    .tag-title {
      font-weight: var(--font-weight-title);
      color: var(--plain-text);
    }
  }

  .alert-filter-badge {
    display: flex;
    align-items: center;
    line-height: 1.5;
  }

  .tag-title {
    font-size: var(--font-size-title);
    font-weight: var(--font-weight-light);
    display: inline-block;
    overflow: hidden;
    min-width: 3em;
    max-width: 7.5em;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
    color: var(--plain-text);
  }

  .remove-icon-box-relative {
    display: none;
    position: relative;
    width: 95%;
    margin-top: -15px;

    .remove-icon-box {
      position: absolute;
      left: 105%;
      top: -17px;
      cursor: pointer;
      width: 20px;
      height: 27px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .draggable-icon-box {
      position: absolute;
      margin-top: -11px;
      right: 105%;
      cursor: grab;
    }
  }
}
