.EndpointFilterRuleCondCellRenderer {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;

  .ccr-deprecated {
    font-style: italic;
    margin-inline-end: 5px;
  }

  .ccr-prefixer {
    font-weight: var(--font-weight-medium);
    color: var(--info-blue);
    margin-right: 10px;
  }

  .ccr-value {
    font-family: var(--font-family-monospace);
    font-weight: var(--font-weight-light);
    color: var(--plain-text);
  }

  .ccr-method {
    margin-left: 10px;
  }
}
