$app-purple: rgb(67, 106, 206);

.ant-checkbox-wrapper {
  color: var(--plain-text);

  &:hover {
    .ant-checkbox-inner {
      border: 1px solid rgba($app-purple, 0.7);
    }
  }

  .ant-checkbox {

    &:hover {
      .ant-checkbox-inner {
        border: 1px solid rgba($app-purple, 0.7);
      }
    }

    .ant-checkbox-inner {
      border: 1px solid rgba($app-purple, 0.3);
    }

    &.ant-checkbox-indeterminate {
      .ant-checkbox-inner::after {
        background-color: var(--app-purple);
      }
    }

    &.ant-checkbox-checked {
      &::after {
        border-color: rgba($app-purple, 0.7);
      }

      .ant-checkbox-inner {
        background-color: var(--app-purple);
      }
    }
  }
}
