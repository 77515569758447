.card-collector-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100%;

  .cell-content[title="rpm"], .cell-content[title="iso"] {
    text-transform: uppercase;
  }
}

.between-card {
  margin-bottom: 24px;
}

.state {
  display: flex;
  align-items: center;
  margin-left: 16px;

  span:first-child {
    text-transform: capitalize;
  }

  span {
    margin-right: 4px;
  }

  span.collector-state-description {
    text-transform: none;
    margin-inline: 5px;
  }
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 4px;

  &.node-enabled {
    background-color: var(--stopLight-green);
  }

  &.node-pending_enabled,
  &.node-pending_disabled,
  &.node-pending_deleted,
  &.node-applying_config,
  &.node-upgrading {
    background-color: var(--stopLight-yellow);
  }

  &.node-errored,
  &.node-disabled.node-image-failed {
    background-color: var(--stopLight-red);
  }

  &.node-disabled,
  &.node-deleted {
    background-color: var(--stopLight-gray);
  }
}
