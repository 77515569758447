@import '../../../styles/mixins';

.service-container {
  gap: 20px;
  padding: var(--app-window-padding);

  .service-expandable-divider {
    margin: 0;
    border-color: transparent;
    transition: border 0.2s 0.5s;

    &.show {
      border-color: var(--app-purple-shade-three);
      border-width: 1px;
      transition: border 0.2s;
    }
  }
}
