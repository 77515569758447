@import '../../styles/fonts.scss';

.UiPagination {
  display: flex;
  align-items: center;
  @include text-regular;

  .ant-btn[disabled] {
    background-color: transparent;
  }

  .uip-text {
    padding-block-start: 2px;
  }
}
