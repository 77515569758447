@import "../../../../styles/fonts";
@import "../discoveryDashboardSharedStyles";

@mixin pieWidgetBase() {
  // for some reason the pies are rendered with scrollbar. this fixes it:
  height: 99%;

  .pie-widget-title {
    @include title2;
    min-height: 25%;
  }

  .pie-widget-chart {
    flex-grow: 1;
    max-width: calc(100% - $base-widget-padding-right-left);
  }
}