@import '../../../../../styles/fonts';

.CollectorConfigurationAddEdit {
  display: flex;
  flex-direction: column;
  height: 100%;

  $left-side-width: 20%;

  header {
    display: flex;
    justify-content: space-between;

    .buttons {
      display: flex;
      gap: 12px;
    }
  }

  .content {
    margin-top: 36px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .config-name-input {
      display: flex;
      width: 100%;
      gap: 35px;

      input {
        width: 235px;
      }

      .data-source-filenames-field {
        p {
          margin-bottom: 0;
        }
      }
    }

    .files-editor-content {
      display: flex;
      flex-grow: 1;

      @mixin files-navigator-general-style {
        box-shadow: 0 4px 30px var(--container-box-shadow);
        border-radius: 3px;
      }

      .files-navigator {
        width: $left-side-width;
        min-width: max-content;
        @include files-navigator-general-style;
        margin-right: 10px;

        .ant-menu-item-icon {
          position: absolute;
          right: -3px;
          color: var(--critical-red);
          transform: scale(1.3) translateY(2px);
        }
      }

      .file-content {
        width: calc(80% - #{$left-side-width});
        @include files-navigator-general-style;
        margin-left: 35px;
        height: 100%;
        font-family: var(--font-family-monospace);
        resize: none;
      }
    }
  }

  .port-warning {
    @include text-regular;
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--critical-red);
  }
}