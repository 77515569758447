.ui-dropdown {
  display: flex;
  align-items: center;
}

.ant-select {
  height: var(--dropdown-select-height);
  border-radius: 3px;

  .ant-space-item {
    margin-right: 12px;
  }

  &:not(.ant-select-disabled):hover {
    .ant-select-selector {
      border: 1px solid var(--app-purple-shade-two);
    }
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item {
    line-height: 32px !important;
  }

  .ant-select-selector {
    min-height: 36px;
  }

  &.ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid var(--app-purple-shade-two);
  }

  &.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
      height: 36px;
      border: 1px solid var(--app-purple-shade-two);
    }
  }

  .ant-select-arrow {
    color: var(--app-purple);
    top: 50%;
  }

  &.ant-select-open {
    &:not(.ant-select-show-search ) {
      .ant-select-arrow {
        transform: rotate(180deg);
      }
    }

    &.ant-select-single {
      .ant-select-selection-item {
        opacity: 1;
      }
    }
  }
}

.ant-select-selection-search-input {
  height: 100%;
}

.icon-item {
  display: flex;
  align-items: center;
  height: 100%;

  .label {
    padding-left: 8px;
  }
}

.ant-select-dropdown {
  .ant-select-item {
    &.ant-select-item-option-selected, &:hover {
      background: var(--grey-background-share-two);
    }
  }

  .icon-item {
    display: flex;
    align-items: center;
    height: 100%;

    .label {
      padding-left: 8px;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight: 500;
}