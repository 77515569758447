@import './../../styles/fonts';
@import './../../styles/mixins';

.SingleCallDetails {
  @include callDetails;
}

.ns-link {
  color: var(--plain-text);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
