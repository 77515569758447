@import "../../styles/fonts";

.settings-container {
  height: 100%;

  .settings-menu {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    background: var(--settings-bg);
    height: 100%;
    box-shadow: 0 3px 3px rgb(0, 0, 0, 0.2);
    overflow-x: hidden;

    .settings-menu-items {
      position: relative;
      height: 100%;
      overflow-y: auto;
    }

    .title-container {
      @include control-label;
      padding: 8px 17px;
      display: flex;
      align-items: center;
      height: 32px;
      background-color: var(--secondary-bg-color);
      border-left: 5px solid transparent;
    }

    .navigation-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      font-size: var(--font-size-medium);
      font-weight: var(--font-weight-light);
      color: var(--plain-text);
      padding: 8px 17px;
      border-left: 5px solid transparent;
      cursor: pointer;

      &:hover {
        border-left: 4px solid var(--app-purple);
        font-weight: var(--font-weight-medium);
        color: var(--app-purple);
      }

      &.selected {
        color: var(--plain-text);
        background-color: var(--app-purple-shade-three);
        font-weight: var(--font-weight-title);
        border-left: 4px solid var(--app-purple);
      }

      &.release-notes-menu-item {
        position: absolute;
        bottom: 0;
      }
    }

    .settings-menu-footer {
      height: 100px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      justify-content: center;
      text-align: center;
      border-top: 1px solid var(--comment-border);

      .settings-menu-footer-title {
        @include text-regular;
        color: var(--app-grayish);
      }

      .settings-menu-footer-link {
        @include title3;
        color: var(--plain-text);
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
      }
    }
  }

  .settings-content-container {
    padding: 18px 24px;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
}
