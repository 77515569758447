@import '../../../../../../styles/colors.scss';

.FindingDetailsOffendingCall {
  padding: 8px 16px;
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: var(--secondary-bg-color);

  .api-tag-container {
    flex-grow: 1;
  }
}
