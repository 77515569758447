.ui-switch {
  display: flex;
  align-items: center;

  .ant-switch-handle::before {
    border: 1px solid var(--app-purple-shade-two);
  }

  .ant-switch {
    &.ant-switch-checked {
      background-color: var(--switch-background);

      .ant-switch-handle::before {
        background-color: var(--app-purple);
        border: 1px solid var(--app-purple-shade-two);
      }
    }
  }

  .switch-text {
    font-size: var(--font-size-text);
    color: var(--plain-text);
    padding-left: 15px;
  }
}
