@import '../../styles/mixins';

.ExpandableContainer {
  @include expandable-box-shadow;
  position: relative;
  width: 100%;
  background: var(--app-white);
  z-index: 100;


  .panel {
    position: relative;
    max-height: fit-content;
    background: var(--app-white);
    overflow: hidden;
    transition: all 0.5s;
    z-index: 1;

    .panel-section {
      overflow: hidden;
      @include expandable-transition;

      &.open {
        opacity: 1;
      }

      &.closed {
        opacity: 0;
      }
    }
  }

  .expand-button {
    position: relative;
    z-index: 10;
  }

  &::after {
    @include expandable-box-shadow;
    content: "";
    width: 45px;
    height: 45px;
    background-color: var(--app-white);
    position: absolute;
    bottom: -21px;
    left: 50%;
    border-radius: 50%;
    transform: translateX(-50%);
    z-index: 0;
  }

  &.overlay {
    position: absolute;
  }

  &.dark {

    .panel {
      background: var(--app-navbar-gradient)
    }

    .expand-button path {
      fill: var(--app-white);
    }

    &::after {
      background: var(--app-navbar-gradient);
    }
  }
}

.overlay-container {
  @include expandable-transition;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  height: 100%;
  opacity: 0;
  z-index: 100;

  &.visible {
    opacity: 1;
  }
}
