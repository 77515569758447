.ui-progress {
  display: flex;
  .extra-info {
    display: flex;
    padding-left: 10px;
    min-width: 150px;
  }
}

.ant-progress {
  .ant-progress-outer {
    .ant-progress-inner {
      background-color: #60699933;

      .ant-progress-bg {
        background-color: #606999cc;
        height: 6px !important;
      }
    }
  }
}
