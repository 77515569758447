.TopAlertingUsers {
  background: var(--app-white);
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: var(--plain-text);

  .skeleton {

    .skeleton-rows {
      justify-content: space-around;
      height: 100%;
      width: 100%;

      .skeleton-table-row {
        display: flex;
        gap: 60px;

        > * {
          flex: 1 1 auto;
        }

        .skeleton-item {
          height: 10px;

          &:first-child {
            height: 16px;
          }
        }
      }

      .skeleton-table-header {

        > div {
          display: flex;
          flex-direction: column;
          gap: 7px;
          padding-right: 20px;

          > .skeleton-item:first-child {
            height: 16px;
          }

          > .skeleton-item:last-child {
            height: 10px;
          }
        }
      }
    }
  }

  .users-table-header {
    display: flex;
    border-bottom: 1px solid var(--app-purple-shade-one);
    padding-bottom: 10px;
    margin-right: 16px;

    .header-col {
      display: flex;
      color: var(--app-grayish);
      text-transform: uppercase;
      white-space: nowrap;
      flex-direction: column;
      justify-content: end;
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);

      .header-col-inner {
        width: fit-content;
        display: flex;
        flex-direction: column;
      }

      & .title {
        padding-top: 5px;
      }

      &-user {
        flex: 0 1 35%;
        align-items: flex-start;
        padding-right: 12px;
      }

      &-alerts {
        flex: 0 0 14%;

        > .anticon {
          padding-right: 0.1rem;
        }
      }

      &-location {
        flex: 0 0 32%;

        > .anticon {
          padding-right: 0.1rem;
        }
      }

      &-active-since {
        flex: 0 0 19%;

        > .anticon {
          padding-right: 0.3rem;
        }
      }
    }
  }

  .users-table-body {
    overflow-y: auto;

    .row {
      display: flex;
      min-height: 64px;
      margin-right: 16px;
      align-items: center;
      border-bottom: 1px solid var(--app-purple-shade-one);

      .user-name {
        flex: 0 1 35%;
        overflow: hidden;
        padding-right: 12px;

        &-text {
          flex-wrap: nowrap;
          overflow: hidden;
          min-width: 0;
          text-overflow: ellipsis;
        }
      }

      .alerts {
        flex: 0 0 14%;
      }

      .location {
        flex: 0 0 32%;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: 40px;
      }

      .active {
        flex: 0 0 19%;
      }

      .cell + .cell {
        padding-right: 12px;
      }
    }
  }

  .info-message {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
}
