@import '../../styles/fonts';

.CommentCard {
  background-color: var(--comment-bg);
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all .5s ease;
  border: 1px solid var(--comment-bg);
  border-inline-start: 3px solid var(--comment-bg);

  &:not(:last-child) {
    margin-block-end: 8px;
  }

  .comment-card-delete {
    opacity: 0;
    pointer-events: none;
    transition: all .5s ease;
    display: flex;
  }

  &:hover {
    border-color: var(--comment-border);
    background-color: #EAECEF;

    .comment-card-delete {
      cursor: pointer;
      opacity: 1;
      pointer-events: all;
    }
  }

  .comment-card-block {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .comment-card-header {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-title);
      color: var(--app-grayish);
      opacity: 0.65;
    }

    .comment-card-content {
      @include text-regular;
      color: var(--plain-text);
      word-break: break-all;
      line-height: 1.5;
    }
  }
}