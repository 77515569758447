@import "./discoveryDashboardSharedStyles";

.DiscoveryDashboard {
  display: grid;
  grid-template-columns: 1fr 1fr 1.561fr 1.561fr;
  grid-template-rows: 1fr 2.298fr 3.875fr;
  gap: 24px;
  height: 100%;
  width: 100%;
  padding: var(--app-window-padding);
  overflow: hidden;

  & > div {
    // the following is needed during 'loading' of the grid. It's a duplication ("included" for each widget as well),
    // but required:
    @include ddWidgetsBaseStyles;

    &:first-child {
      grid-column: 1 / span 2;
    }

    &:nth-child(2) {
      grid-column: 3 / span 2;
      grid-row: 1 / span 2;
    }

    &:nth-child(5) {
      grid-column: 1 / span 2;
    }
  }
}
