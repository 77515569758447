.legend-container {
  display: flex;
  white-space: nowrap;
  flex-wrap: nowrap;
  align-items: flex-start;
  color: var(--plain-text);
  font-size: var(--font-size-text);
  width: 200px;
  margin: 0 auto;

  .title {
    flex: 1;
    display: flex;

    .dot-container {
      width: 18px;
      padding-top: 6px;

      .dot {
        border-radius: 50%;
        width: 9px;
        height: 9px;
      }
    }
  }

  &.dark-legend .value {
    color: var(--app-white);
  }

  .value {
    flex: 1;
    .number {
      font-weight: var(--font-weight-title);
    }
  }
}

