@import '../../styles/mixins';
@import '../../styles/fonts';

.CommentAddPopover {

  .alert-popover-title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    padding-block-end: 16px;
  
    .alert-popover-title {
      @include title3;
      line-height: 19px;
    }
  }
  
  .alert-popover-main-content {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 500px;
  
    .ui-title {
      @include control-label-top;
      line-height: 13px;
      text-transform: uppercase;
    }
  
    .alert-popover-meta-content {
      display: flex;
      flex-direction: column;
  
      .alert-meta-data {
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: var(--plain-text);
      }
    }
  }

  .alert-popover-footer {
    padding: 24px;
    border-top: 1px solid #e3e9f8;
    display: flex;
    justify-content: flex-end;
    gap: 5px;
  }
  
  .input-metadata {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    .char-count {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-text);
      line-height: 20px;
      color: var(--counter-value-color);
    }
  }
  
  .form-control {

    .comment-content {
      border: 1px solid var(--counter-value-color);
      border-radius: 3px;
      min-height: 80px;
      padding: 12px;
    }
  }
  
  .alert-info {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}