.Card {
  border: 1px solid #0000000f;
  border-radius: 2px 2px 0 0;
  box-shadow: 0px 4px 30px var(--container-box-shadow);

  .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 24px;
  }

  .leftHeader {
    display: flex;
    align-items: center;
  }

  .title {
    font-weight: var(--font-weight-title);
    font-size: var(--font-size-title);
    color: var(--plain-text);
  }

  .disabled-title {
    color: var(--plain-text);
    opacity: 0.5;
  }

  .divider {
    border-bottom: 1px solid var(--app-purple-shade-one);
  }
}

.card-background {
  background: var(--app-white);
  box-shadow: 0px 4px 30px var(--container-box-shadow);
}

.card-disabled-background {
  background: var(--list-background);
}
