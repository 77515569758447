.query-builder {
  color: var(--app-purple);

  .query-builder-heading {
    display: flex;
    align-items: center;
    gap: 30px;
    margin-bottom: 12px;
  }

  .query-builder-content {
    max-height: 270px;
    overflow: auto;
    padding: 0 0 18px;
  }

  .box-title {
    padding-bottom: 0;
  }

  .clear-text-button {
    text-decoration: underline;
    cursor: pointer;
  }

  .query-elements {
    display: flex;
    flex-direction: column;
    padding: 16px 0;

    .query-element {
      padding: 12px 32px;
      position: relative;
      border: 1px solid transparent;
      height: 86px;
      display: flex;
      align-items: flex-end;
      gap: 10px;

      .remove-row {
        z-index: 1;
        height: 36px;
        display: flex;
        align-items: center;
        margin-left: -25px;
        margin-right: -5px;
      }

      &:first-child > .remove-row {
        display: none;
      }

      &.hover-query-row {
        background-color: rgba(235, 238, 246, 0.3);
        border: 1px solid var(--app-purple-shade-three);
      }

      .default-element-title {
        display: block;
        font-size: var(--font-size-small);
        padding-bottom: 8px;
        color: #606999b3;
        font-weight: var(--font-weight-semibold);
        text-transform: uppercase;
      }
    }
  }

  .add-row {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding-left: 32px;

    .icon-label {
      padding-left: 8px;
      color: var(--app-purple);
      font-weight: var(--font-weight-medium);
    }
  }

  .disable {
    color: var(--plain-text);
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
    }
  }
}
