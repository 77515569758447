@import '../../../../../styles/mixins';


.entity-multi-select-list {
  position: relative;
  padding-left: 29px;
  margin-top: -5px;

  .ems-label {
    @include control-label-top;
  }

  .ems-separator {
    display: flex;
    align-items: center;
    color: var(--app-purple);
    margin-bottom: 15px;

    .ems-separator-line {
      width: 100%;
      margin-left: 15px;
      border: 1px solid var(--app-purple-shade-one);
    }
  }
}