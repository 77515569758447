
.add-exception-modal {

  .controls-wrapper {
    padding-top: 10px;

    .control-item {
      display: grid;
      padding: 12px 0;
      grid-template-columns: 170px 1fr ;

    }
  }
}
