@import "../../../styles/mixins";

.endpoint-container {
  gap: 16px;
  padding: 27px 24px;

  .endpoint-table-wrapper {
    height: 100%;
  }

  .endpoint-expandable {
    margin-block: 20px;

    .expanded-statistical-endpoint-data {
      @include expandable-transition;
      display: grid;
      grid-template-columns: 2fr 1fr;
      height: 0;
      border-bottom: 1px solid transparent;
      overflow: hidden;

      &.show {
        height: 177px;
        border-color: var(--app-purple-shade-one);
      }

      .alerts-carousel-container {
        display: inline-block;
        margin-left: 20px;
        padding-right: 10px;

        .carousel-title {
          color: var(--app-purple);
        }
      }
    }
  }

  .expanding-element .seriesContainer {
    z-index: 1;
  }

  .centered-empty-message {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;

    .info-message {
      width: 100%;
    }
  }
}
