
.top-endpoints-container {
  background: var(--app-white);
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 16px 24px;

  .inner-endpoint-container {
    height: 100%;
    overflow: auto;

    .endpoint-row {

      display: grid;
      padding: 15px 0;
      grid-template-columns: 4fr 5fr 3fr;
      grid-gap: 16px;
      overflow: hidden;
      color: var(--plain-text);

      .service-name {
        font-weight: var(--font-weight-title);
      }
    }
  }

  .tag-element {
    width: 35px;
    text-align: center;
  }
}
