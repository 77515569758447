@import 'styles/fonts';
@import "styles/colors";

.ResourceNotFound {

  div:first-child {
    padding-bottom: 20px;
  }

  .ResourceNotFoundContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--app-white);

    .title {
      font-size: var(--font-size-menu);
      font-weight: var(--font-weight-semibold);
      color: var(--app-grayish);
    }

    .text {
      font-size: var(--font-size-menu);
      font-weight: var(--font-weight-medium);
      color: var(--new-table-title);
      margin-bottom: 16px;
    }

  }
}