.AlertFilterList {
  display: flex;
  height: 48px;
  background: var(--app-white);
  position: relative;
  overflow: hidden !important;

  .afl-item-container {
    display: flex;
  }

  a.active {
    background: transparent;
    font-weight: var(--font-weight-title);
  }
}
