.ApiLabel {
  display: flex;
  overflow: hidden;
  align-items: center;

  &.disabled {
    opacity: 0.7;
  }

  &.pending-deletion {
    opacity: 0.7;
    cursor: default;
  }

  .api-tag-container {
    display: flex;
    gap: 5px;

    .api-tag {
      padding: 0;
      margin: 0;
      color: var(--tag-color);
      border-color: transparent;
      background-color: transparent;
      border-radius: 0;
      font-size: var(--font-size-sub);
      line-height: 16px;

      &:not(:last-child):after {
        content: ',';
        color: #000;
      }
    }
  }

  .api-name {
    padding: 0 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    -webkit-box-orient: vertical;
    color: var(--plain-text);
    font-weight: var(--font-weight-medium);

    &.api-name-with-path:hover {
      color: #40a9ff;
      text-decoration: underline;
    }

    &.api-name-without-path {
      opacity: 0.7;
    }

    &.bold {
      font-weight: 600;
    }
  }
}
