@import "../../styles/_colors";
@import "../../styles/_fonts";

.tenant-drawer {
  position: absolute;
  height: 100%;

  .ant-drawer-header {
    background-color: var(--tenant-list-background);
    border-bottom: none;

    .ant-drawer-title {
      @include title2;
      padding-top: 12px;
      color: var(--app-white);
    }
  }

  .ant-drawer-body {
    background-color: var(--tenant-list-background);
    padding-top: 12px;
  }

  .ant-drawer-footer {
    padding: 24px 0;
    color: var(--app-white);
    background-color: var(--tenant-list-background);
    border-top: 1px solid var(--tenant-list-border);

    & > div {
      @include subtitle;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    button {
      background-color: black;
      border-color: black;

      &:hover {
        background-color: var(--tenant-list-hover-background);
      }
    }
  }

  .title {
    .ui-title {
      color: var(--app-white);
    }
  }

  .list {

    .tenant-container {
      @include subtitle;
      color: var(--app-white);
      background-color: var(--tenant-list-background);
      padding: 12px 16px;
      width: 100%;
      margin-bottom: 12px;
      border: 1px solid var(--tenant-list-border);
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: var(--tenant-list-hover-background);;
      }

      &.active {
        background-color: rgba(237, 240, 247, 1);
        color: var(--tenant-list-background);
      }

      .tenant-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
