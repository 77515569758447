
.GenericDetails {
  padding: 15px 28px;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 6fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 12px 20px;
  grid-template-areas:
"description item-1 item-2"
"description item-3 .";

  .item {
    overflow: hidden;

    .title {
      text-transform: uppercase;
    }

    .text-container {
      color: var(--plain-text);
      margin-top: 8px;
    }
  }

  .ant-divider-vertical {
    height: 100%;
    background: var(--divider);
  }
}

