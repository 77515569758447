.summary-container {
  color: var(--app-purple);
  min-height: 80px;
  display: flex;
  justify-content: space-between;

  .left-side {
    display: flex;
    flex-direction: column;

    .title-header {

      .title {
        padding: 4px 0;
        display: flex;
        align-items: baseline;

        .api-name {
          color: inherit;
        }

        .severity-icon {
          padding-left: 12px;
        }
      }

      .date-wrapper {
        padding: 4px 0;
        display: flex;
        align-items: center;

        .ui-title .medium {
          font-size: var(--font-size-sub);
        }

        .date-divider {
          height: 11px;
        }

        .ui-title {
          white-space: nowrap;
        }
      }
    }
  }

  .right-side {
    display: flex;
    align-items: flex-end;

    .status-ctrl {
      display: flex;
      gap: 15px;
      align-items: center;

      .ui-title .small {
        font-size: var(--font-size-small);
        font-weight: var(--font-weight-semibold);
        opacity: 0.7;
      }

      .status-label {
        font-size: 14px;
        margin-left: 5px;
      }

      .status-btn-container {
        width: 100px;
      }
      
      .dropdown-container {
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: var(--app-purple);
        color: white;
        padding: 0px 16px;
        width: 100px;
        height: 36px;
        border-radius: 3px;
        font-size: var(--font-size-medium);
        font-weight: var(--font-size-title);
        cursor: pointer;      
      }

      .comment-count {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      
        span {
          font-size: var(--font-size-small);
          font-weight: var(--font-weight-semibold);
          color: var(--risk-info-color);
        }
      }
    }

    .status {
      font-size: 11px;
    }

    .drop-down {
      margin: 0 24px 0 12px;
    }

    .icon {
      margin-left: 32px;
    }
  }
}

.UiDropdownOverlay {

  &.ant-dropdown {

    .ant-dropdown-menu {
  
      .ant-dropdown-menu-item {
  
        .summary-details-action-item {
  
          svg {
            height: unset;
            width: unset;
          }
        }
      }
    }
  }
}


.summary-details-action-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 12px;
  width: 215px;

  .icon-container {
    width: 20px;
    display: flex;
    justify-content: center;
  }

  span {
    color: var(--plain-text);
    font-size: var(--font-size-text);
    font-weight: var(--font-weight-title);

    &.item-text {
      line-height: 20px;
    }
  }
}