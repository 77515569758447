@import './colors';
@import './fonts';
@import './dimensions';


@mixin line-clamp($noOfLines) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $noOfLines;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

@mixin control-label-top() {
  @include control-label;
  margin-bottom: 8px;
}

@mixin control-label-inline() {
  @include control-label;
  padding-right: 10px;
}


@mixin long-text-ellipsis($maxWidth) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: $maxWidth;
  display: inline-block;
  vertical-align: middle;
}

@mixin card-list-grid($noOfCols) {
  display: grid;
  grid-template-columns: repeat($noOfCols, minmax(0, 1fr));
  column-gap: 27px;
  row-gap: 24px;
}

@mixin card-list-page($noOfCols) {
  height: 100%;
  display: flex;
  flex-direction: column;

  .subtitle {
    font-size: var(--font-size-title);
    height: var(--subtitle-height);
    margin: var(--subtitle-margins);
  }

  .content {
    flex-grow: 1;

    .card-grid {
      @include card-list-grid($noOfCols);
      padding-bottom: 18px;
    }
  }
}

@mixin expandable-box-shadow {
  box-shadow: 0 2px 20px var(--expandable-box-shadow);
}

@mixin expandable-transition {
  transition: all 0.7s;
}

@mixin callDetails() {
  height: 100%;
  display: flex;

  .ant-tabs.ant-tabs-top.ant-tabs-card .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane.ant-tabs-tabpane-active {
    overflow-y: auto;
  }

  .list {
    height: 100%;
    position: relative;

    .ag-row .ag-cell {
      padding: 0;
    }

    .ag-header-row, .ag-header-viewport, .ag-header {
      min-height: 52px !important;
      border: none;
    }

    .ag-header-cell {
      background-color: var(--lighter-grey);
      min-height: 52px;
      padding: 15px 24px;
      text-transform: uppercase;
      width: 100%;

      &-text {
        color: var(--plain-text);
        @include text-regular;
      }
    }

    .split-view {
      overflow: hidden auto;

      .split-view-view {
        height: auto;
      }
    }

    .header-row, .list-row {
      min-height: 38px;
    }

    .header-row {
      padding: 15px 24px;
      background-color: var(--lighter-grey);
      text-transform: uppercase;
      width: 100%;
      min-height: 52px;

      .group-title {
        color: var(--plain-text);
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-text);
      }
    }

    .list-row {
      display: flex;
      padding: 15px 24px;
      border-bottom: 1px solid var(--app-purple-shade-three);
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
      color: var(--app-grayish);

      &.key {
        font-weight: var(--font-weight-semibold);
      }

      &.value {
        flex: 1
      }
    }
  }
}
