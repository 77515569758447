
.overlay-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  &.disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }

  .overlay-menu {

    .ant-dropdown-menu-item {
      font-size: var(--font-size-text);
      color: var(--plain-text);
      padding-right: 40px;


      &.ant-dropdown-menu-item-disabled {
        opacity: 0.5;
      }

      &:hover:not(.ant-dropdown-menu-item-disabled) {
        background-color: var(--grey-background-share-two);
      }
    }
  }
}

