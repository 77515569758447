.Spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: var(--app-secondary-background);
    z-index: 1;

    .spinner-container {

        .ant-spin.ant-spin-spinning.ant-spin-show-text.spinner {
            color: var(--app-purple);

            i.ant-spin-dot-item {
                background-color: var(--app-purple);
            }

            .ant-spin-text {
                position: relative;
            }
        }
    }
}
