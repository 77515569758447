@import "../../../../styles/mixins";

.service-summary-container {
  @include expandable-transition;
  height: 78px;
  position: relative;
  font-weight: normal;
  padding: 20px;
  z-index: 100;
  opacity: 1;

  &.closed {
    height: 0;
    padding-block: 0;
    overflow: hidden;
    opacity: 0;
  }

  .service-row {
    justify-content: space-between;

    .text {
      .icon {
        margin-left: 15px;
      }
    }
  }

  .arrow-change-icon {
    display: inline-flex;
  }
}
