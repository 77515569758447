.EndpointFilterList {
  display: flex;
  flex-direction: column;
  height: 100%;

  .endpoint-filter-list-table {
    height: 100%;
    overflow: hidden;

  }
}
