.suppression-rules-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .suppression-rules-table {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
