.ui-column-progress{
  width: 95px;

  .extra-info{
    display: inline-block;
    margin-right: 5px;
    width: 35px
  }
  .ant-progress{
    width: 50px;
    display: inline-block;
    .ant-progress-outer{
      .ant-progress-inner{
        .ant-progress-bg{
          height: 4px !important;
        }
      }
    }
  }
}
