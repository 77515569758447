.SettingsSectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .ui-title .x-large {
    color: var(--app-dark-background);
    font-size: var(--font-size-xlarge);
    font-weight: var(--font-weight-medium);
  }
}
