.ant-select-dropdown {
  .api-tag-container {
    .api-name {
      width: 345px;
      max-width: unset;
      font-weight: var(--font-weight-title);
      color: var(--plain-text);
    }
  }
}

.query-row {
  display: flex;
  align-items: flex-end;
  gap: 10px;

  .api-tag-container {
    height: 100%;
  }

  .conditions {
    display: flex;
  }

  .add-condition {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 36px;
    white-space: nowrap;

    .icon-label {
      padding-left: 8px;
      color: var(--app-purple);
      font-weight: var(--font-weight-medium);
    }
  }

  .disable {
    color: var(--plain-text);
    pointer-events: none;
  }
  .disable:hover {
    cursor: not-allowed;
  }
}
