@import "../../../../../styles/fonts";
@import "../../discoveryDashboardSharedStyles";
@import "../pieWidgetsSharedStyles";

.DiscoveryServiceRiskWidget {
    @include ddWidgetsBaseStyles;
    overflow: hidden;

    @include pieWidgetBase;
}
