.discovery-container {
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: 470px 3fr;
  width: 100%;

  .Pane.vertical.Pane1 {
    max-width: 50%;
    min-width: 500px;

    .endpoint-list-container {
      width: 100%;
      overflow: hidden;
      position: relative;
      height: 100%;
    }
  }

  .discovery-master-detail-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
}
