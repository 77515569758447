@import '../../../../../styles/_mixins.scss';

.CollectorList {
  @include card-list-page(1);
  height: calc(100% - 46px);
  padding: 24px;
  overflow-y: auto;
  background-color: var(--secondary-bg-color);

  .content .card-grid {
    padding: 0;
  }
}