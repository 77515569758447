@import '../../../../../styles/mixins';

.DataSourceList {
  @include card-list-page(4);

  .subtitle {
    font-size: var(--font-size-medium);
  }
}

