$color_1: #669acc;
$color_2: #cc3333;
$color_3: #ff4455;
$color_4: #666666;
$color_5: #000000;
$color_6: #00cc00;
$color_7: #aaaaaa;
$color_8: #999999;
$color_9: #cccccc;
$color_10: #333333;
$color_11: inherit;
$color_12: #2d4ea1;
$font-family_1: monospace;
$font-family_2: Verdana, sans-serif;
$font-family_3: sans-serif;
$background-color_1: #f5f5f5;
$background-color_2: #ffff99;
$background-color_3: #00cc00;
$background-color_4: transparent;
$background-color_5: #ffffff;
$background-color_6: #f2f2f2;
$background-color_7: #eeeeee;
$background-color_8: #f4f4f4;
$background-color_9: #dddddd;
$background-color_10: #888888;
$background-color_11: #cccccc;
$background-color_12: #ffffcc;
$background-color_13: #f3f3f3;
$background-color_14: #fbeb93;
$background-color_15: #fffef4;
$background-color_16: #f9f6dc;

.xonomyBubble {
    .menuItem {
        &:focus {
            background-color: $background-color_1 !important;
            outline: none;
        }
    }
    .menuLabel {
        &:focus {
            background-color: $background-color_1 !important;
            outline: none;
        }
    }
}
.xonomy {
    &:focus {
        outline: none;
    }
    .tag.focused {
        > .name {
            outline: 1px dotted #666666;
        }
    }
    .textnode.focused {
        margin-left: -2px !important;
        > .value {
            border: 1px dotted #666666;
        }
    }
    .attribute.focused {
        .attributeName {
            outline: 1px dotted #666666;
        }
    }
    .attributeValue.focused {
        outline: 1px dotted #666666;
    }
    .childrenCollapsed.focused {
        outline: 1px dotted #666666;
        background-color: $background-color_2 !important;
    }
    .rollouter.focused {
        border: 1px dotted #666666 !important;
        background-color: $background-color_2 !important;
        margin-left: 2px !important;
    }
    .inlinecaption {
        a {
            color: $color_11;
            text-decoration: none;
            &:hover {
                color: $color_12;
            }
        }
    }
    div.modeSwitcher {
        position: fixed;
        bottom: 0px;
        left: 0px;
        width: 70px;
        height: 28px;
        background-color: $background-color_5;
        cursor: pointer;
        border: 1px solid #cccccc;
        z-index: 12;
        span.nerd {
            display: inline-block;
            width: 35px;
            height: 28px;
            background-image: url(tag.png);
            background-position: center center;
            background-repeat: no-repeat;
        }
        span {
            background-color: $background-color_13;
        }
        &:hover {
            box-shadow: 0px 0px 1px #999999;
        }
    }
    div.layby {
        position: fixed;
        bottom: 0px;
        top: 0px;
        right: 0px;
        padding: 10px 10px 0px 10px;
        background-color: $background-color_15;
        z-index: 12;
        border-left: 3px solid #cccccc;
        z-index: 9;
        span.button {
            display: inline-block;
            width: 30px;
            height: 30px;
            background-position: center center;
            background-repeat: no-repeat;
            background-color: $background-color_5;
            border: 1px solid #cccccc;
            cursor: pointer;
            &:hover {
                box-shadow: 0px 0px 1px #333333;
            }
        }
        div.message {
            display: none;
            position: absolute;
            top: 40px;
            left: 20px;
            right: 20px;
            bottom: 0px;
            overflow: hidden;
            font-family: $font-family_3;
            font-size: 1.25rem;
            line-height: 1.5em;
            text-align: center;
            padding-top: 100px;
            color: $color_9;
        }
        > div.content {
            position: absolute;
            top: 50px;
            left: 10px;
            right: 10px;
            bottom: 0px;
            overflow-x: hidden;
            overflow-y: auto;
            white-space: nowrap;
        }
    }
    div.layby.closed {
        width: 0px;
        padding: 5px 10px 0px 5px;
        cursor: pointer;
        &:hover {
            background-color: $background-color_16;
        }
        span.closer,
        span.purger,
        div.content {
            display: none;
        }
    }
    div.layby.open {
        width: 30%;
        span.closer {
            display: inline-block;
        }
        div.content {
            display: block;
        }
    }
    div.layby.empty {
        span.purger {
            display: none;
        }
    }
    div.layby.nonempty.open {
        span.purger {
            display: inline-block;
        }
    }
    div.layby.empty.open {
        div.message {
            display: block;
        }
    }
    div.layby.activeDropper {
        background-color: $background-color_16;
    }
}
.xonomy.nerd {
    .element {
        > .children {
            > .textnode {
                .char.focused {
                    > .selector {
                        position: absolute;
                        left: 0px;
                        right: 0px;
                        z-index: 2;
                        background-color: $background-color_3;
                        height: 3px;
                        bottom: -3px;
                        > .inside {
                            position: absolute;
                            left: 0px;
                            right: 0px;
                            top: -2px;
                            bottom: -1px;
                            background-color: $background-color_4;
                        }
                    }
                }
                > .value {
                    color: $color_5;
                    cursor: pointer;
                    > .insertionPoint,
                    > .dots {
                        display: none;
                    }
                    &:hover {
                        color: $color_4;
                    }
                }
                .word {
                    white-space: nowrap;
                }
                .char.on {
                    background-color: $background-color_2;
                    > .selector {
                        position: absolute;
                        left: 0px;
                        right: 0px;
                        z-index: 2;
                        background-color: $background-color_3;
                        height: 3px;
                        bottom: -3px;
                        > .inside {
                            position: absolute;
                            left: 0px;
                            right: 0px;
                            top: -2px;
                            bottom: -1px;
                            background-color: $background-color_4;
                        }
                    }
                }
            }
            > .textnode.whitespace {
                > .value {
                    &:hover {
                        background-color: $background-color_6;
                    }
                }
            }
            > .textnode.current {
                > .value {
                    background-color: $background-color_2 !important;
                    color: $color_6;
                    padding: 0px 3px;
                    margin: 0px -3px;
                    border-radius: 2px;
                }
            }
        }
        .punc {
            color: $color_1;
            z-index: 1;
        }
        > .tag {
            border-radius: 2px;
            > .name {
                color: $color_2;
                pointer-events: none;
                &:hover {
                    color: $color_6;
                }
            }
        }
        > .tag.opening {
            > .punc.slash {
                display: none;
            }
            > .attributes {
                > .attribute {
                    > .name {
                        color: $color_3;
                        &:hover {
                            color: $color_6;
                        }
                    }
                    > .valueContainer {
                        > .value {
                            color: $color_4;
                        }
                        &:hover {
                            > .value {
                                color: $color_6;
                            }
                        }
                    }
                    > .valueContainer.current {
                        background-color: $background-color_2;
                        padding: 0px 3px;
                        margin: 0px -3px;
                        border-radius: 2px;
                        > .value {
                            color: $color_6;
                            border-radius: 2px;
                        }
                    }
                }
                > .attribute.current {
                    > .name {
                        background-color: $background-color_2;
                        color: $color_6;
                        padding: 0px 3px;
                        margin: 0px -3px;
                        border-radius: 2px;
                    }
                }
            }
            > .attributes.rolledout {
                display: block;
                padding: 0px 0px 5px 15px;
                border-left: 1px dotted #cccccc;
                margin: 0px 0px 0px 10px;
                > .attribute {
                    display: block;
                    border: 0px;
                    padding: 0px;
                    margin: 0px;
                }
                > .attribute.invisible {
                    display: none;
                }
            }
        }
        padding-left: 25px;
        margin-top: 5px;
        margin-bottom: 5px;
        .connector {
            border-top: 1px dotted #b6b6b6;
            width: 24px;
            height: 10px;
            position: absolute;
            top: 0.75em;
            left: 0px;
        }
        .children {
            margin-left: 10px;
            border-left: 1px dotted #cccccc;
            padding-top: 1px;
            padding-bottom: 1px;
            .textnode {
                padding-left: 25px;
                pointer-events: none;
            }
            .textnode.empty {
                min-height: 1em;
                .value {
                    cursor: pointer;
                    > .insertionPoint {
                        display: inline;
                        padding: 0px 2px;
                        border-top: 1px solid #dddddd;
                        border-bottom: 1px solid #dddddd;
                        > .inside {
                            border-left: 1px solid #dddddd;
                        }
                    }
                    &:hover {
                        background-color: $background-color_6;
                    }
                    &:focus {
                        background-color: $background-color_6;
                    }
                }
                &:first-child {
                    &:last-child {
                        .value {
                            > .dots {
                                display: inline;
                                padding-left: 2em;
                            }
                        }
                    }
                }
            }
        }
        > .connector {
            > .plusminus {
                background-image: url(minus.gif);
                width: 9px;
                height: 9px;
                position: absolute;
                top: -5px;
                left: -5px;
                cursor: pointer;
            }
        }
        > .childrenCollapsed {
            display: none;
            border: 1px solid #cccccc;
            color: $color_8;
            border-radius: 2px;
            margin: 0px 2px;
            padding: 0px 5px 0px 5px;
            cursor: pointer;
            font-size: 1rem;
            &:hover {
                background-color: $background-color_8;
            }
        }
        > .inlinecaption {
            margin-left: 0.5em;
            margin-right: 0.5em;
        }
        > .prominentChildren {
            margin-left: 0.25em;
            > .element {
                display: inline;
                margin-right: 0.25em;
                padding: 5px 10px;
                background-color: $background-color_13;
                border-radius: 2px;
                > .tag,
                > .connector {
                    display: none;
                }
            }
        }
    }
    font-family: $font-family_1;
    font-size: 1rem;
    cursor: default;
    background-color: $background-color_5;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 1.5em;
    padding-left: 5px;
    * {
        position: relative;
    }
    .element.current {
        > .tag {
            > .name {
                background-color: $background-color_2;
                color: $color_6;
                padding: 0px 3px;
                margin: 0px -3px;
                border-radius: 2px;
            }
        }
    }
    .readonly {
        * {
            color: $color_7 !important;
            cursor: default !important;
            -webkit-user-select: text;
            -moz-user-select: text;
            -ms-user-select: text;
            user-select: text;
            cursor: text !important;
        }
        .plusminus {
            cursor: pointer !important;
        }
        .childrenCollapsed {
            cursor: pointer !important;
        }
        .textnode {
            .char {
                > .selector {
                    > .inside {
                        &:hover {
                            background-color: $background-color_4 !important;
                        }
                    }
                }
            }
        }
    }
    .element.readonly.draggable {
        > .connector {
            > .draghandle {
                cursor: move !important;
            }
        }
    }
    .element.readonly {
        .warner {
            .inside {
                cursor: pointer !important;
            }
        }
        .element {
            .draghandle {
                display: none;
            }
        }
    }
    .invisible,
    .attribute.shy {
        display: none;
    }
    .element.oneliner {
        .connector {
            top: 0.75em;
        }
        .children {
            .textnode {
                .dots {
                    display: none !important;
                }
                padding-left: 0px;
                display: inline;
            }
            margin-left: 0px;
            border-left: 0px;
            padding: 0px;
            display: inline;
        }
        .element {
            padding-left: 0px;
            display: inline;
        }
        .element.invisible {
            display: none;
        }
        .element.hasText {
            .children {
                margin-left: 0px;
                border-left: 0px;
                padding: 0px;
                display: inline;
            }
        }
    }
    .element.hasText {
        > .children {
            > .element {
                padding-left: 0px;
                display: inline;
            }
            > .element.invisible {
                display: none;
            }
        }
        .children {
            .connector {
                position: relative;
                top: 0px;
                left: 0px;
                display: inline;
                padding-right: 16px;
                border: 0px;
            }
            .element.uncollapsible {
                .connector {
                    padding-right: 0px;
                }
            }
            .element.noChildren {
                .connector {
                    padding-right: 0px;
                }
            }
            padding-left: 25px;
            padding-top: 5px;
            padding-bottom: 5px;
            .textnode {
                padding-left: 0px;
                display: inline;
            }
        }
        .element.hasText {
            .children {
                margin-left: 0px;
                border-left: 0px;
                padding: 0px;
                display: inline;
            }
        }
        .element.oneliner {
            .connector {
                .plusminus {
                    top: 4px;
                    left: 4px;
                }
            }
        }
        .element.oneliner.collapsed > .tag,
        .element.uncollapsible .connector,
        .element .connector .draghandle {
            display: none;
        }
    }
    .textnode {
        > .connector {
            display: none !important;
        }
    }
    .element.collapsed {
        > .connector {
            > .plusminus {
                background-image: url(plus.gif);
            }
        }
        > .children {
            display: none !important;
        }
        > .childrenCollapsed {
            display: inline;
        }
    }
    .element.noChildren {
        > .connector {
            > .plusminus {
                display: none;
            }
        }
        > .children,
        > .tag.closing,
        > .childrenCollapsed {
            display: none;
        }
        > .tag.opening {
            > .punc.slash {
                display: inline;
            }
        }
    }
    .tag.opening {
        span {
            pointer-events: none;
        }
    }
    .element.uncollapsible {
        > .connector > .plusminus,
        > .childrenCollapsed {
            display: none;
        }
    }
    .element.draggable {
        > .connector {
            > .draghandle {
                display: block;
            }
        }
    }
    .dragging {
        color: $color_9 !important;
        * {
            color: $color_9 !important;
        }
        .draghandle {
            opacity: 0.5;
        }
    }
    .elementDropper {
        display: block;
        margin-top: 0px;
        margin-bottom: 0px;
        height: 0px;
        position: relative;
        > .inside {
            display: block;
            height: 9px;
            width: 9px;
            border: 1px solid #ffffff;
            background-color: $background-color_9;
            position: absolute;
            top: -8px;
            left: -6px;
            z-index: 10;
        }
        &:first-child {
            > .inside {
                top: -1px;
            }
        }
        &:only-child {
            > .inside {
                top: -5px;
            }
        }
    }
    .activeDropper {
        > .inside {
            background-color: $background-color_10;
        }
    }
    .hasInlineMenu {
        > .children {
            > .textnode {
                .char {
                    > .selector {
                        position: absolute;
                        left: 0px;
                        right: 0px;
                        bottom: -2px;
                        height: 1px;
                        background-color: $background-color_7;
                        z-index: 2;
                        > .inside {
                            position: absolute;
                            left: 0px;
                            right: 0px;
                            bottom: -2px;
                            top: -3px;
                            background-color: $background-color_4;
                            &:hover {
                                background-color: $background-color_11;
                                left: -1px;
                                right: -1px;
                            }
                        }
                    }
                }
            }
        }
    }
    span.space {
        font-weight: bold;
        color: $color_6;
    }
    .element.invalid {
        > .tag {
            > .warner {
                display: inline-block;
            }
        }
    }
    .attribute.invalid {
        > .warner {
            display: inline-block;
        }
    }
    .inlinecaption {
        &:empty {
            display: none;
        }
        font-family: $font-family_2;
        font-size: 0.8em;
        color: $color_8;
        font-weight: normal;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
        cursor: text !important;
    }
    div.modeSwitcher {
        span.nerd {
            background-color: $background-color_14;
        }
    }
    div.layby {
        > div.content {
            > .element {
                margin-left: 10px;
            }
        }
    }
    .prominentChildren {
        &:empty {
            display: none !important;
        }
    }
    .name {
        .displayName {
            font-style: italic;
        }
    }
}
.xonomyBubble.nerd {
    display: none;
    position: absolute;
    z-index: 10;
    min-width: 100px;
    > div.inside {
        box-shadow: 0px 0px 5px #99cbff;
        border: 1px solid #333333;
        background-color: $background-color_9;
        border-radius: 2px;
        margin-top: 8px;
        > .xonomyBubbleContent {
            font-family: $font-family_2;
            font-size: 0.8rem;
            color: $color_4;
            margin: 5px;
            padding: 5px;
            background-color: $background-color_5;
            border-radius: 2px;
        }
    }
    span.punc {
        color: $color_1;
    }
    .xonomyBubbleContent {
        div.menu {
            margin: -5px;
            max-height: 250px;
            overflow-y: auto;
            white-space: nowrap;
        }
        div.menuItem {
            padding: 8px 20px 7px 10px;
            border-top: 1px solid #dddddd;
            cursor: pointer;
            margin-top: -1px;
            background-color: $background-color_5;
            div.menuLabel {
                margin: -8px -20px -7px -10px;
                padding: 8px 20px 7px 30px;
                background-color: $background-color_5;
                background-image: url(plus.gif);
                background-position: 10px center;
                background-repeat: no-repeat;
                font-weight: bold;
                &:hover {
                    background-color: $background-color_12;
                }
            }
            span.icon {
                display: inline-block;
                margin: -8px 0px -7px 0px;
                width: 18px;
                img {
                    max-height: 15px;
                    max-width: 20px;
                    display: inline-block;
                }
            }
            span.keyCaption {
                float: right;
                margin-left: 2em;
                margin-right: -0.5em;
                color: $color_8;
            }
            &:hover {
                background-color: $background-color_12;
            }
        }
        div.submenu {
            margin: 0px -20px -7px -10px;
            display: none;
            div.menuItem {
                padding-left: 30px;
            }
        }
        div.menuItem.expanded {
            > div.submenu {
                display: block;
            }
            div.menuLabel {
                background-image: url(minus.gif);
            }
            > div.menuLabel {
                margin-bottom: 0px;
                padding-bottom: 7px;
            }
        }
        span.techno {
            font-family: $font-family_1;
            font-size: 0.85rem;
            span.punc {
                color: $color_1;
            }
            span.atName {
                color: $color_3;
            }
            span.atValue {
                color: $color_4;
            }
            span.elName {
                color: $color_2;
            }
        }
        div.menuItem.techno {
            padding-top: 6px;
            padding-bottom: 6px;
            font-family: $font-family_1;
            font-size: 0.9rem;
            span.explainer {
                font-family: $font-family_2;
                font-size: 0.8em;
                color: $color_8;
                font-weight: normal;
                margin-left: 3px;
            }
        }
        .warning {
            padding: 5px 10px;
        }
    }
}
