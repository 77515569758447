@import '../../../../../styles/fonts';

.CollectorConfigurationAddModal {
  .ant-modal-content {

    .ant-modal-header {
      border: none;
    }

    .ant-modal-body {
      background-color: var(--app-white);
      margin: 0 24px 24px 24px;

      .modal-body {
        flex-direction: column;
      }
    }

    .ant-modal-footer {
      padding-bottom: 24px;
    }

    .multiple-ds-warning {
      @include text-regular;
      display: flex;
      gap: 10px;
      color: var(--critical-red);
    }

    .ant-form-item-label {
      margin-bottom: 5px;
    }
  }
}
