
.CellRendererCustomMappingRule{
  .type-text {
    color: var(--info-blue);
    font-weight: var(--font-weight-light);
    margin-right: 4px;
  }

  .name-text {
    color: var(--data-orange-color);
    font-weight: var(--font-weight-light);
    margin: 0 4px;
  }
}