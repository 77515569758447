@import "~antd/dist/antd.css";
@import "allotment/dist/style.css";
@import "styles/antOverrides.scss";
@import "styles/ag-grid-overrides";
@import "styles/mixins";

.app {
  height: 100vh;
  width: 100vw;
  background: var(--app-gray-background);
  display: flex;
  flex-direction: column;

  .app-container {
    flex-grow: 1;
    overflow: hidden;
    position: relative;
  }
}

html {
  font-family: var(--font-family-plain-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-text);
  line-height: 20px;
}

body {
  margin: 0;

  .text {
    font-size: var(--font-size-text);
    color: var(--plain-text);
  }

  .bold {
    font-weight: var(--font-weight-title);
  }
}

.ag-theme-alpine {
  .ant-spin.ant-spin-sm.ant-spin-spinning.spinner {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 20px;

    .ant-spin-text {
      padding-left: 12px;
    }
  }
}

.control-with-label {
  height: 28px;
  background-color: #fdfdfd;
  margin-right: 20px;
  padding: 0 8px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: center;
}

.panel-header {
  font-weight: normal;
}

.panel-title {
  font-style: normal;
  text-align: left;
  font-weight: var(--font-weight-title);
  font-size: var(--font-size-title);
  line-height: 20px;
  margin-bottom: 12px;
}

.section-header-title {
  font-weight: var(--font-weight-title);
  font-size: var(--font-size-title);
  text-transform: uppercase;
  margin-bottom: 8px;
  color: var(--app-purple);
}

.zero-opacity {
  opacity: 0
}

.hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

.clamp-1-line {
  @include line-clamp(1);

  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  -webkit-box-orient: vertical;
}


.clamp-2-lines {
  @include line-clamp(2);
}


.pre-wrap {
  white-space: pre-wrap;
  word-break: keep-all;
  line-height: 1.5 !important;
}

.no-wrap {
  white-space: nowrap;
}


.break-word {
  overflow-wrap: break-word
}

.rotate-90 {
  transform: rotate(90deg);
  display: flex;
  justify-content: center;
}

.rotate-90:hover {
  cursor: pointer;
}

.hundred-pct-width {
  max-width: 100%;
  width: 100%;
}

.disabled {
  opacity: 0.25;
  pointer-events: none;
}

.disable-pointer-events{
  pointer-events: none;
}
