@import '../../../../styles/_fonts.scss';
@import "../discoveryDashboardSharedStyles";

.EmptyState {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .widget-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .widget-title {
      @include title2
    }

    .no-data {
      border-radius: 8px 0px 0px 8px;
      border: 1px solid #DDD9D9;
      border-right: 0px;
      background: var(--app-gray-background);
      position: absolute;
      right: -$base-widget-padding-right-left;

      display: inline-flex;
      padding: 2px 1px;
      justify-content: center;
      align-items: center;

      span {
        @include title2;
        text-align: center;
        color: var(--closed);
        margin: 0 10px;
      }
    }
  }

  .widget-empty-state-svg{
    display: flex;
    height: 70%;

    img{
      width: 100%;
    }
  }

}