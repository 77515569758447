.endpoint-redirect{
  display: inline;
  position: relative;
  width: 100%;
  margin-bottom: 12px;

  .endpoint-redirect-title{
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-xlarge);
    line-height: var(--font-size-xlarge);
    color: var(--plain-text);
    display: inline-block;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }

  .breadcrumb-with-endpoints {
    display: flex;
    height: 20px;
    margin-bottom: 16px;

    .endpoint-select-container {
      padding-left: 8px;
      padding-top: 3px;

      .endpoint-redirect-control {
        width: 154px;
      }

      .search-endpoint-breadcrumb {
        font-size: var(--font-size-text);
        color: #222A35;
      }
    }
  }

  .endpoint-select-container{
    width: 600px;
    display: inline;

    .endpoint-redirect-control {
      padding-left: 0;
      width: 154px;
      height: calc(var(--dropdown-select-height) + 5px);
      position: absolute;
      background: var(--grey-background);
    }

    .search-endpoint-breadcrumb{
      font-size: var(--font-size-text);
      color: #222A35;
    }

    .search-endpoint svg{
      margin-left: 7px;
      height: 20px;
      width: 20px;
      color: var(--plain-text);
    }
  }
  .ant-select{
    &.ant-select-single:not(.ant-select-customize-input) {
      .ant-select-selector {
        z-index: 10;
        width: 366px;;
      }
    }
  }
}

