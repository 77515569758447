
.OverviewDashboard {
  height: 100%;
  overflow: hidden;
  padding: var(--app-window-padding);
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  .widget-header {
    border-bottom: 1px solid var(--app-purple-shade-one);
    padding: 20px 24px;

    .ui-title .x-large {
      font-weight: var(--font-weight-medium);
    }
  }

  .widget-content {
    flex: 1 1 auto;
    padding: 16px 24px;
  }

  .alert-overview {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .top-alerting-users {
    overflow: hidden;
    position: relative;

    .user-name {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
  }

  .top-endpoints {
    grid-column: 1 / span 2;
    grid-row: 2;
    overflow: hidden;
    position: relative;
  }

  .recent-alerts-container {
    grid-column: 3;
    grid-row: 1 / span 2;
    overflow: hidden;
    position: relative;
  }

  .custom-icon-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .skeleton {
    height: 100%;

    .skeleton-item {
      background: var(--app-gray-background);
      position: relative;
      z-index: 1;
      -webkit-mask: linear-gradient(-60deg, #000 40%, #0005, #000 60%) right/300% 100%;
      background-repeat: no-repeat;
      animation: shimmer 2.5s infinite;
    }
  }
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left
  }
}
