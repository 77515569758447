@import "../../styles/mixins";

.alerts-container {

  .table-header {
    display: flex;
  }

  .alerts-tabs {
    position: relative;

    .alerts-tabs-action {
      display: flex;
      gap: 5px;
      position: absolute;
      right: 24px;
      top: 60px;
      border-radius: 3px;
      z-index: 1;
    }
  }

  .alert-table {
    overflow: hidden;
    padding: 16px 24px 24px 24px;
    flex: 1;
    min-height: 40px;

    .title-row {
      min-height: 45px;
    }

    .cardinality-drawer {
      top: 110px;
      min-height: 30%;
      max-height: 50%;

      .ant-drawer-header {
        height: 60px;
        color: #3B5EB8 !important;
      }

      .ant-drawer-title {
        color: var(--plain-text);
        font-size: var(--font-size-title);
        font-weight: var(--font-weight-medium);
      }

      .ant-drawer-content-wrapper {
        background: var(--app-white);
        height: calc(100% - 36px);

        .drawer-body-head {
          color: #9b9ba0;
        }
        .drawer-form-filters {
          min-height: 100px;
        }

        .validation-message {
          color: #E22B2B;
          position: absolute;
          right: 0;
        }

        .ant-drawer-content {
          height: 100%;

          .ant-drawer-wrapper-body {
            height: 100%;

            .ant-drawer-body {
              padding: 12px 48px 24px 24px;
              height: 100%;
            }
          }
        }
      }
    }

    .ant-drawer-footer {
      height: 60px;
      padding: 10px 0;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    .footer-switcher-container {
      position: relative;
      min-width: 200px;
      display: flex;
      align-items: center;
    }

    .footer-switcher-span {
      margin-left: 10px;
    }

    .footer-buttons-container {
      margin-top: 2px;

      .footer-button {
        margin: 0 8px;
        border-radius: 3px;
      }

      .footer-button:hover {
        cursor: pointer;
      }
    }
  }
}
