@import '../../../../../styles/fonts.scss';
@import '../../../../../styles/colors.scss';

.FindingDetailsTimeline {
  $tail-head-position: 75px;
  padding: 16px 24px;
  background-color: var(--settings-bg);
  height: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .timeline-title {
    @include new-table-title;
    text-transform: uppercase;
  }

  .fdt-timeline-item {
    margin-block-start: 3px;
    display: flex;
    gap: 10px;

    .fdt-icon, {
      width: 22px;
    }

    .fdt-status-code {
      display: flex;
      justify-content: end;
      color: var(--text-dark-theme);
      flex-grow: 1;
    }
  }

  .ant-timeline {
    flex-grow: 1;
    height: 0;
    overflow: auto;
  }
}