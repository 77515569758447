.ui-date-picker-container {
  display: flex;
  align-items: center;

  .label {
    padding-right: 10px;
  }

  .ant-picker {
    height: 36px;
    width: 420px;
    border: 1px solid var(--app-purple-shade-two) !important;

    &:hover {
      border: 1px solid var(--app-purple-shade-two) !important;
    }

    &.ant-picker-range {
      .ant-picker-active-bar {
        background: var(--app-purple);
      }
    }
  }

  .ant-picker-input input {
    font-size: var(--font-size-text);
  }
}

.ant-picker-cell-in-view {
  &.ant-picker-cell-selected, &.ant-picker-cell-range-start, &.ant-picker-cell-range-end {
    .ant-picker-cell-inner {
      background: var(--app-purple);
    }
  }
  &.ant-picker-cell-today {
    .ant-picker-cell-inner::before {
      border-color: var(--app-purple);
    }
  }
}
