@import "../../styles/colors";

@mixin btn-primary {
  background-color: var(--app-purple);
  border-color: var(--app-purple);
  color: var(--app-white);
}

@mixin btn-secondary {
  background-color: var(--app-white);
  border-color: var(--app-purple);
  color: var(--app-purple);
}

.ant-btn {
  height: 36px;
  padding: 0 12px;
  border-radius: 3px;

  span {
    text-transform: capitalize;
    font-size: var(--font-size-sub);
    font-weight: var(--font-weight-semibold);
  }

  &.ant-btn-primary {
    @include btn-primary;

    &:hover {
      background-color: var(--btn-primary-hover);
      border-color: var(--btn-primary-hover)
    }

    &:focus {
      background-color: var(--btn-primary-focus);
      border-color: var(--btn-primary-focus);
    }
  }

  &.ant-btn-secondary {
    @include btn-secondary;
    --btn-secondary-hover: #eef1fb;
    --btn-secondary-focus: #d0d9f2;

    &:hover {
      background-color: var(--btn-secondary-hover);
    }

    &:focus {
      background-color: var(--btn-secondary-focus);
    }

    &:hover, &:focus {
      border-color: var(--app-purple);
      color: var(--app-purple);
    }
  }

  &.ant-btn-text {
    font-size: var(--font-size-text);
    padding: 0;
    height: fit-content;
    color: var(--app-purple);

    span {
      text-decoration: underline;
      font-weight: var(--font-weight-medium);
    }

    &:hover {
      color: var(--btn-primary-hover);
      background-color: transparent;
    }

    &:focus {
      color: var(--btn-primary-focus);
      background-color: transparent;
    }

    &.text-with-icon {
      display: flex;
      align-items: center;

      &.reverse {
        flex-direction: row-reverse;
      }
    }
  }

  &.ant-btn-link {
    color: var(--app-purple);

    &:hover {
      color: var(--btn-secondary-hover);
    }
  }

  .anticon + span {
    margin: 0 6px;
  }

  &[disabled] {
    opacity: 0.5;
    background: var(--app-white);

    &.ant-btn-primary[disabled] {
      @include btn-primary;

      &:hover, &:focus {
        @include btn-primary;
      }
    }

    &.ant-btn-secondary[disabled] {
      @include btn-secondary;

      &:hover, &:focus {
        @include btn-secondary;
      }
    }

  }

}
