.navigation-menu {
  ul.ant-menu.ant-menu-root.ant-menu-horizontal {
    li.ant-menu-item.ant-menu-item-only-child {
      margin: 0 16px;
      padding: 0;

      a {
        color: var(--app-white);
      }
    }
  }
}
