.menu-filter-tag-list {
  display: inline-flex;
  align-items: start;
  flex-wrap: wrap;

  .ant-menu {
    margin-left: 30px;
    padding-left: 0 !important;

    &-item {
      margin: 0 !important;
    }

    .ant-btn.clear-all {
      color: var(--app-purple);
      padding-left: 0px;
      height: 26px;

      span {
        font-weight: var(--font-weight-medium);
        text-decoration: underline;
      }
    }

    .ant-menu-item.ant-menu-item-only-child.empty-tab {

      .ant-tag.filter-tag {
        padding: 2px 0px 2px 10px;
        background-color: var(--light-grey);
        color: var(--plain-text);
        display: flex;
        border: 1px solid var(--border);
        align-items: center;
        margin-right: 16px;
        margin-bottom: 8px;
        font-size: var(--font-size-text);

        .anticon.anticon-close.ant-tag-close-icon {
          display: flex;
          align-items: center;
          color: var(--app-purple);
          height: 10px;
          width: 16px;

          svg {
            height: 100%;
          }
        }
      }

      .ant-tag.filter-tag.dotted{
        border: 1px dashed var(--border);
        background-color: var(--app-background);
        color: gray;
      }
    }
  }

  .menu-clean {
    background-color: var(--app-gray-background);
    display: flex;
    align-items: center;
  }

  .services-filters-list {
    margin-left: 0px;
  }

  .empty-tab {
    min-width: 130px;
    padding: 0px;
  }

  .ant-menu-horizontal > .ant-menu-item.ant-menu-item-active {
    border-bottom: none;
  }
}
