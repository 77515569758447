.suppression-rule {

  .sr-conditions {
    padding: 15px;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .sr-separator {
    display: flex;
    align-items: center;
    color: var(--app-purple);

    .sr-separator-line {
      width: 100%;
      margin-left: 15px;
      border: 1px solid var(--app-purple-shade-one);
    }
  }

  .sr-predicate-container {

    &:last-child {
      margin-bottom: 15px;
    }
  }

  .sr-predicate {
    display: flex;
    align-items: center;
    padding: 30px 10px;
    border: 1px solid transparent;

    &:hover {
      background: var(--hover-background);
      border: 1px solid var(--hover-border);

      .sr-remove {
        visibility: visible;
      }
    }
  }

  .sr-remove {
    visibility: hidden;
    margin-right: 10px;
  }
}