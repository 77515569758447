@import './../../styles/mixins';

.breadcrumb-container {

  .breadcrumb-item {
    text-decoration: underline;
    color: var(--plain-text);
    cursor: pointer;
    @include long-text-ellipsis(300px);
  }

  .breadcrumb-item:hover {
    color: var(--app-purple);
  }

  .breadcrumb-item-current {
    font-weight: 500;
    @include long-text-ellipsis(300px);
  }

  .ant-breadcrumb-separator {
    font-weight: bold;
  }
}

.breadcrumb-tooltip.ant-tooltip {
  padding-left: 84px;

  .ant-tooltip-content {

    .ant-tooltip-inner {
      width: max-content;
      z-index: 10;
    }

    .ant-tooltip-arrow {
      left: 24px;
      z-index: 1;
    }
  }
}