@import '../../../../../styles/mixins';

.AkamaiConfigurationsList {
  .card-grid {
    @include card-list-grid(2);
    padding: 18px 0;
  }

  .subtitle {
    font-size: var(--font-size-medium);
  }
}

