@import '../../styles/fonts';

.TroubleshootingModal .FormModal .ant-modal-content .ant-modal-body .form-modal-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
  background: transparent;
  box-shadow: none;

  .searchbar {
    width: auto;

    .ant-input-affix-wrapper {
      height: 36px;
      width: 278px;

      .ant-input {
        height: 100%;
        @include subtitle;

        &:focus, &:hover {
          // Important to override style tag of .ant-input
          border: none !important;
        }
      }

      .ant-divider {
        top: 0;
      }
    }

    .ant-input-search-button {
      padding: 0;
    }
  }

  .collapse-container {
    max-height: 334px;
    overflow-y: auto;
    box-shadow: 0 4px 30px var(--container-box-shadow);

    .troubleshooting-collapse {
      &.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        color: var(--app-purple);
        font-weight: var(--font-weight-title);
        font-size: var(--font-size-text);
        border-bottom: none;

        .ant-collapse-arrow {
          top: 50%;
        }
      }

      .ant-collapse-item .ant-collapse-content {
        border-top: none;

        .ant-collapse-content-box {
          color: var(--plain-text);
          font-weight: var(--font-weight-light);
          padding: 0 46px 13px 24px;
        }
      }
    }
  }
}