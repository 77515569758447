@import '../../../styles/fonts';

.alert-charts-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.light-chart {
    background: var(--app-white);
  }
  &.dark-chart {
    background: var(--app-navbar-gradient);

    .title {
      color: var(--app-white);
    }
    .totals-container {
      path {
        stroke: var(--app-dark-background) !important;
      }
    }
  }

  .title {
    @include headline2;
    padding-left: 24px;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-semibold);
    opacity: 0.7;
    color: var(--app-grayish);
    .grp-title {
      font-size: var(--font-size-text);
      font-weight: var(--font-weight-medium);
    }
  }

  .totals-container {
    width: 33%;
    margin: 20px 6px 24px 20px;
  }

  .seriesContainer {
    flex: 1;
    padding: 20px 20px 24px 6px;
    border-left: 1px solid var(--app-purple-shade-one);
    height: 100%;
  }

  &.dark-chart {
    .seriesContainer {
      border-left: 1px solid var(--stopLight-gray);
    }
  }

  .recharts-tooltip-wrapper {
    border: 1px solid #436ace4d;
    box-shadow: 0 10px 20px rgba(117, 130, 166, 0.25);
    border-radius: 4px;
    border-image: none;
    outline: transparent;
  }
}
