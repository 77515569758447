.tag-multi-select {
  width: 100%;

  .ant-select-selection-item {
    border-radius: 20px;
    border: 1px solid var(--divider);
  }
  .ant-select-selection-item-remove{
    color: var(--app-purple);
  }
}
.tag-multi-select-dropdown {
  .multi-select-button-panel {
    margin: 4px 16px;
    display: flex;
    justify-content: space-between;
  }
  .ant-divider {
    margin: 8px 0;
  }

  &.ant-tree-select-dropdown {
    padding: 8px 4px;
  }
}
