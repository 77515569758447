@import '../../styles/_fonts.scss';

.UiToggle {

  .uit-label {
    @include new-table-title;

    &--inactive {
      font-weight: var(--font-weight-light);
    }
  }

  .uit-switch {
    margin: 0 10px;

    &.ant-switch {
      background-color: var(--switch-background);
    }

    .ant-switch-handle::before {
      background-color: var(--app-purple);
      border: 1px solid var(--app-purple-shade-two);
    }
  }
}
