@import '../findingDetailsContainer';

.FindingDetailsFinding {
  @include findingDetailsContainer;
  flex-grow: 1;

  .finding-details-finding-content {
    flex-grow: 1;

    .finding-details-description {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex-grow: 1;
      height: 0;
      overflow: auto;
    }
  }

  .FindingDetailsApplicationScan,
  .FindingDetailsScan {
    display: flex;
    justify-content: space-between;
  }

  .FindingDetailsApplicationScan {
    width: 100%;
  }
}
