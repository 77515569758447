.actions-dropdown-overlay {
    .services-action-item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding-left: 6px;
        min-width: 215px;

        .item-icon {
            width: 20px;
            display: flex;
            justify-content: center;
        }

        .item-text {
            color: var(--plain-text);
            font-size: var(--font-size-text);
            font-weight: var(--font-weight-medium);

            &:hover {
                font-weight: var(--font-weight-title);
            }
        }
    }
}
