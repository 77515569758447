@import '../../styles/fonts';

.PrettyRenderer {
  width: 100%;
  padding: 24px;
  background-color: var(--secondary-bg-color);
  
  .content {
    width: 100%;
    min-height: 100px;
    background-color: var(--app-white);
    padding: 16px;
    position: relative;
    word-break: break-all;
    white-space: pre-wrap;

    &.is-loading {
      padding-top: 30px;
    }

    .copy-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      background-color: var(--app-white);
      color: var(--bright-blue);
      border: none;
      padding: 0;
    }
  }

  .ant-radio-group {
    display: flex;
    margin-bottom: 24px;
  
    .ant-radio-button-wrapper {
      @include buttonText;
      color: var(--app-purple);
      border: 1px solid var(--app-purple);
      padding: 10px 16px;
      display: flex;
      align-items: center;
  
      &.ant-radio-button-wrapper-disabled {
        color: var(--button-disabled);
        border-color: var(--button-disabled);
      }
  
      &:first-of-type {
        border-radius: 3px 0px 0px 3px;
      }
      &:last-of-type {
        border-radius: 0px 3px 3px 0px;
      }
  
      &.ant-radio-button-wrapper-checked {
        background-color: var(--app-purple);
        color: var(--app-white);
      }
    }
  }
}