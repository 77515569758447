.AkamaiConfigurationEdit {

  .section {
    display: flex;
    justify-content: space-between;
    margin: 5px 0 20px 0;
  }

  .actions {
    display: flex;
    gap: 12px;
  }

  .pluginName {
    color: var(--app-grayish);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semibold);
    text-transform: uppercase;
    opacity: 0.7;
  }

}