.four-o-four-container {
  width: 100%;
  height: 60%;
  display: grid;
  place-items: center;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      font-size: 80px;
    }
    .copy{
      font-size: 24px;
      margin-bottom: 30px;
    }
  }
}
