@import "../../../../styles/_fonts.scss";
@import "../discoveryDashboardSharedStyles";

.DiscoveryServiceRiskChangeWidget {
  @include ddWidgetsBaseStyles;

  .dd-title-container {
    padding-block-end: 24px;

    .ddsrc-subtitle {
      @include new-table-title;
      padding-top: 2px;
      text-transform: uppercase;
    }
  }

  .ddsrc-chart-container {
    flex-grow: 1;
    display: flex;
  }
}
