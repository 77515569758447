@import '../../styles/fonts';

.gridCell {
  padding: 24px;

  .label {
    @include control-label;
  }

  .cell-content {
    color: var(--plain-text);
  }
}

.double-cell-height {
  grid-row: 1 / 3;
}
