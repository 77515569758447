.ant-select-dropdown {
  .ant-select-item-group {
    .custom-group {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--app-purple-shade-three);

      span {
        font-size: 11px !important;
        color: var(--app-grayish);
        opacity: 0.8;
      }
    }
  }

  .api-option {
    display: flex;
    align-items: center;
    height: 100%;

    span:nth-child(2) {
      padding-left: 12px;
    }
  }
}

.ant-select {
  .api-option {
    display: flex;
    align-items: center;
    height: 100%;

    span:nth-child(2) {
      padding-left: 12px;
    }
  }
}

.arrow-icon {
  transform: translate(-5px, 2px);
  
  &.expanded-icon {
    transform: rotate(180deg) translate(6px, 2px);
  }
}
