@import '../../../../../styles/fonts';

.troubleshoot-deploy {
  margin: 12px 0;

  .link {
    @include link;
  }
}

.DeployCollectorModal {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .hardware-requirements pre {
    @include plain-text;
    font-weight: var(--font-weight-subtitle);
    font-family: var(--font-family-plain-text);
    margin: 0;
    white-space: break-spaces;
  }

  .deploy-instructions {
    padding-left: 16px;
  }

  .section-content {
    @include text-regular;
    position: relative;
    height: fit-content;
    padding: 16px 24px 16px 16px;
    border: 1px solid var(--app-background);
    white-space: pre-line;
    overflow-y: auto;
    opacity: 0.7;

    &.install-link {
      background: var(--grey-background);
      min-height: 54px;
      max-height: 164px;
      font-family: monospace;
      white-space: normal;
    }

    ol {
      margin: 0;
      padding: 0 0 0 16px;
    }

    .copy-button {
      color: var(--bright-blue);
      border: none;
      height: 0;
      width: 0;
      padding: 0;
      position: absolute;
      right: 25px;
      top: 10px;

      span {
        position: relative;

        svg {
          flex: 1 0 auto;
        }
      }
    }
  }

  .subtitle {
    color: var(--plain-text);
    margin-bottom: 6px;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-title);
  }

  .link {
    @include link;
  }

  .deploy-collector-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 100%;
  }
}
