@import '../../../../../styles/mixins';

.EntityList {
  height: 100%;
  display: flex;
  flex-direction: column;

  .subtitle {
    font-size: var(--font-size-title);
    margin: var(--subtitle-margins);
  }

  .content {
    flex-grow: 1;
  }

  .entities-table {
    height: 100%;
  }

  .hide-show-entity {
    display: none;
  }

  .ag-row-hover .hide-show-entity {
    display: initial;
  }
}

.el-confirm-modal {

  .suppression-link {
    @include link;
  }
}
