@import '../../styles/fonts.scss';

.navbar {
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  background: var(--app-navbar-gradient);
  border-bottom: 1px solid var(--app-purple-shade-two);

  .mast-head {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    .tenant-button {
      max-width: 20px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-inline-end: 12px;
    }

    .title-and-tenant-container {
      @include title();
      height: 100%;
      display: flex;
      align-items: center;
      color: var(--app-white);

      .tenant-separator {
        margin-block-start: 5px;
        background-color: var(--app-white);
        height: 21px;
        user-select: none;
        margin-inline-end: 20px;
      }

      .tenant-name {
        @include title3;
        margin-block-start: 5px;
        color: var(--app-white);
        max-width: 215px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        direction: rtl;
      }

      .detok-icons {
        position: relative;
        margin-block-start: 5px;
        display: flex;
        align-items: center;
        width: 0;
        overflow: visible;
        margin-inline-start: 12px;

        .three-stars {
          height: 13px;
          padding: 0 3px;
          border: 1px solid white;
        }

        .check-circle {
          position: relative;
          background-color: var(--app-dark-background);
          top: -7px;
          right: 7px;
        }
      }
    }

    .app-name {
      margin-left: 12px;
      font-size: 24px;
      font-weight: 600;
      color: var(--app-white);
      padding-bottom: 4px;
    }
  }

  .controls {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-direction: row;
    height: 100%;

    .navbar-user-badge {
      &:hover {
        // nullify the CSS that is inherited from below (border-bottom on flex items)
        border-bottom: none;
      }
    }

    > * {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 150ms;

      &:hover {
        border-bottom: 2px solid var(--app-white);
      }
    }

    .tab-button {

      &:hover {
        cursor: pointer;
      }

      &.active {
        border-bottom: 2px solid var(--app-white);
        opacity: 1;
      }
    }

    .icon-button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .ant-menu {
    background: transparent;
  }
}
