@import 'styles/_fonts';
@import 'styles/_colors';

.ServicesTableActions {
    display: flex;

    .table-actions {
        display: flex;
        align-items: center;

        .selected-service-action {
            opacity: 70;
            color: var(--new-table-title);
            font-size: var(--font-size-small);
            font-weight: var(--font-weight-title);
            margin-right: 10px;
        }

        .download-oas-action {
            display: flex;
            align-items: center;
            color: var(--body-text-80);
            font-weight: var(--font-weight-medium);
            font-size: var(--font-size-text);
            cursor: pointer;

            .download-icon {
                padding-top: 3px;
            }
        }

        .vertical-divider {
            background: var(--body-text-80);
            margin: 0 40px 0 10px;
        }
    }

    .show-hide-by-activity {
        display: flex;
        align-items: center;
    }
}
