@import '../../../styles/_fonts.scss';
$base-widget-padding-right-left: 24px;
$base-widget-padding-top-bottom: 16px;

@mixin ddWidgetsBaseStyles() {
  box-shadow: 0 2px 20px 0 var(--container-box-shadow);
  padding: $base-widget-padding-top-bottom $base-widget-padding-right-left;
  overflow: auto;
  background: white;
  display: flex;
  flex-direction: column;

  .dd-title-container {
    display: flex;
    justify-content: space-between;

    .link-to-services{
      @include link;
    }

    .widget-title {
      @include title2
    }
  }

    .widget-chart {
      flex-grow: 1;
    }
}