
.alerts-carousel {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .carousel-title.box-title {
      padding-bottom: 0;
    }

    .controls {
      padding-left: 24px;
      display: flex;
    }
  }

  .alert-item.box {
    padding: 24px;
  }
}
