@import '../../../../../styles/mixins';

.RunLocallyModal {

  section {

    &:not(:first-child) {
      margin-block-start: 24px;
    }

    .subtitle {
      @include text-medium;
      margin-block-end: 8px;
    }

    .section-content {
      &.instructions > ol {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0;
        margin: 0;
        list-style-position: inside;

        > li {
          @include text-regular;
        }
      }

      &.command {
        @include body-light;
        display: flex;
        position: relative;
        min-height: 32px;
        max-height: 164px;
        padding: 8px;
        background: var(--grey-background);
        font-family: monospace;

        .copy-button {
          color: var(--bright-blue);
          border: none;
          height: 0;
          width: 0;
          padding: 0;
          position: absolute;
          right: 25px;
          top: 10px;

          span {
            position: relative;

            svg {
              flex: 1 0 auto;
            }
          }
        }

        .command-content {
          padding-inline-end: 16px;
          overflow-y: auto;

          // The following line keeps \n as new line after render
          white-space: pre-line;
        }
      }
    }
  }
}