@import '../../../../../styles/_mixins.scss';
@import '../../../../../styles/_fonts.scss';

.add-entity-wizard-container {
  background: var(--app-white);
  margin-top: 24px;
  box-shadow: 0 4px 30px var(--container-box-shadow);

  .add-entity-wizard-body {
    padding: 0 24px;
  }

  .add-entity-confirmation {
    display: flex;
    flex-direction: column;
    height: 356px;

    .entity-preview {
      flex: 1 1 auto;
      padding: 16px 24px 10px 24px;

      .subtitle {
        @include subtitle();
        font-size: 13px;
      }

      .entity-details {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        margin-top: 10px;

        .gridCell {
          padding: 0;

          // Capitalize entity family - Actor/Business
          &:is(:nth-child(2), :nth-child(3)) .cell-content {
            text-transform: capitalize;
          }
        }
      }
    }

    .ant-divider {
      background: var(--divider);
    }

    .entity-creation-warning {
      padding: 0 24px 24px 24px;
      font-weight: var(--font-weight-title);
    }
  }

  .ant-radio-wrapper {
    min-width: 72px;
  }
}
