.CallDetailsModal {
  display: flex;
  flex-direction: column;
  align-items: baseLine;
  height: 85vh;

  .ant-modal-content {
    overflow: hidden;
    background-color: var(--app-gray-background);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    .ant-modal-header {
      .header {
        font-size: var(--font-size-title);
        color: var(--plain-text);
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-title,
        .header-controls {
          display: flex;
          align-items: center;
        }

        .header-title {

          .ui-title .medium {
            font-size: var(--font-size-medium);
            font-weight: var(--font-weight-medium);
            color: var(--plain-text);
          }

          > * {
            margin-right: 8px;
          }
        }

        .header-controls {
          padding-inline-end: 40px;
          gap: 10px;
        }

        .curl-button {
          margin-top: -16px;
          margin-bottom: -16px;
        }
      }

      .ui-title {
        white-space: nowrap;

        .api-tag-container .api-name {
          color: inherit;
          font-size: var(--font-size-title);
          font-weight: var(--font-weight-semibold);
        }
      }
    }

    .ant-modal-body {
      flex: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
      padding: 12px 24px;

      .modal-body {
        height: 100%;
        overflow: hidden;

        > div {
          height: 100%;
          overflow: hidden;
        }
      }
    }

    .single-call-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
}

