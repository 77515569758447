.UiAgGridSSRM, .UiAgGridCSRM {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .spinner-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background: var(--app-secondary-background);

    .hidden {
      display: none;
    }
  }
}

.UiAgGridSSRM {
  display: flex;
  flex-direction: column;

  .title-row {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    min-height: 30px;

    .ant-btn {
      display: flex;
      float: left;
      height: 20px;

      span {
        font-weight: var(--font-weight-medium);
      }
    }
    
    .title {
      font-size: var(--font-size-title);
      font-weight: var(--font-weight-medium);
      padding-block-end: 10px;
      margin-inline-end: 20px;
    }
  }

  .grid-wrapper {
    flex: 1;
    position: relative;

    .ag-theme-alpine.ag-grid-ssrm {
      height: 100%;
      width: 100%;
    }
  }
}
