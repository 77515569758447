.ant-modal-content {
  background: var(--app-gray-background);

  .ant-modal-header {
    border-bottom: 1px solid #C5C6C980;
  }

  .modal-body {
    display: flex;
    align-items: center;
    gap: 30px;
    overflow-wrap: anywhere;

    .circle-icon {
      background: var(--app-white);
      display: grid;
      place-items: center;
      min-width: 70px;
      width: 70px;
      height: 70px;
      box-shadow: 0 4px 30px var(--container-box-shadow);
      border-radius: 100%;
      svg {
        width: 35px;
      }  
    }
  }
}

.FormModal {
  .ant-modal-header,
  .ant-modal-content,
  .and-modal-body {
    background: var(--app-gray-background);
  }

  .ant-modal-content {
    .ant-modal-header {
      padding: 20px;
      border-bottom: none;

      .ant-modal-title {
        font-size: var(--font-size-title);
        font-weight: var(--font-weight-medium);
        color: var(--plain-text);
      }
    }

    .ant-modal-body {
      padding: 0 20px;

      .form-modal-body {
        padding: 24px;
        border-radius: 3px;
        box-shadow: 0 4px 30px var(--container-box-shadow);
        background: var(--app-white);
      }
    }

    .ant-modal-footer {
      padding: 20px;

      .custom-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .ant-btn-primary {
          margin-right: 0;
        }
      }
    }
  }
}
