.CommentCount {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  
  span {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semibold);
    color: var(--risk-info-color);
    position: relative;

    &.count {
      position: absolute;
      top: 0px;
      line-height: 17px;
    }

    &.hide-value {
      visibility: hidden;
    }
  }
}