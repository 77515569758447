@import "styles/_fonts";
@import "styles/_colors";

.IconTableActions {
    display: flex;

    .delete-endpoints-container {
        display: flex;
        align-items: center;

        .selected-endpoints-count {
            opacity: 70;
            color: var(--new-table-title);
            font-size: var(--font-size-small);
            font-weight: var(--font-weight-title);
            margin-right: 10px;
        }

        .delete-link {
            display: flex;
            align-items: center;
            color: var(--plain-text);
            font-weight: var(--font-weight-medium);
            font-size: var(--font-size-text);
            cursor: pointer;

            .delete-icon {
                cursor: pointer;
                margin-right: 5px;
            }
        }

        .vertical-divider {
            height: 15px;
            width: 1px;
            background: var(--plain-text);
            margin: 0 10px;
        }
    }
}
