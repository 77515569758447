
.discovery-table {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  .tables-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    flex: 1;

    button.ant-drawer-close {
      padding: 16px 0;
      color: var(--app-purple);
    }

    .ant-drawer-title {
      color: var(--plain-text);
    }

    .ant-tabs {

      .ant-tabs-content-holder {
        padding: 12px 18px;
      }

      .main-table {
        overflow: hidden;
        flex: 1;
        position: absolute;

        .ant-table-header {
          max-height: 36px;
        }
      }
    }

    .cardinality-drawer {
      padding: 48px 0;

      .ant-drawer-header {
        height: 48px;

        .ant-drawer-header-title {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          width: 100%;

          .ant-drawer-close {
            margin: 0;
          }
        }
      }

      .ant-drawer-content-wrapper {
        background: var(--app-white);

        .ant-drawer-content {
          height: 100%;

          .ant-drawer-wrapper-body {
            height: 100%;

            .ant-drawer-body {
              height: 100%;
              padding: 12px 24px 24px;


              .distribution-table {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
