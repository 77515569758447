@import '../../../../styles/fonts.scss';

@mixin findingDetailsContainer() {
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-block-end: 24px;
  }

  .finding-details-container-title {
    @include title3;
    margin-block-end: 10px;
  }

  .finding-details-container-content {
    padding: 16px 24px;
    background-color: var(--secondary-bg-color);
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .ant-divider {
    width: auto;
    margin: 0 -24px;
  }
}
