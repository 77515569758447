@import '../../../../styles/fonts.scss';

.AuthConfigAddEditClone {
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .header-title {
      @include title;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .header-button {
      margin-left: 16px;
    }
  }

  .form {
    display: flex;
    width: 100%;

    .ant-form-item:first-child{
      flex-grow: 1;
    }

    .ant-form-item{
      margin-top: 10px;
    }

    .select-template{
      width: 330px;
      margin-left: 16px;
    }

  }


  .auth-config-editor-empty {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--app-white);
    color: var(--disabled-text);
    font-weight: var(--font-weight-medium);
    font-size: 16px;
  }
}