
.endpoint-table-container {
  display: block;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .discovery-master-detail-container {
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .info-message {
    margin: auto;
  }
}
