@import '../../../../../../styles/fonts';

.TestSuiteModal{

  .label {
    @include control-label;
    align-self: center;
    padding-bottom: 10px;
  }

  .copy-to-clipboard-icon {
    position: absolute;
    top: 70px;
    right: 50px;
  }

}