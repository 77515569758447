.ApplicationList {
  width: 100%;
  height: 100%;

  .UiAgGridSSRM {
    .title-row {
      display: none;
    }

    .header-cell-container {
      gap: 8px;
      align-items: center;
    }
  }
}