.UiTabsNav {
  background-color: var(--app-white);
  box-shadow: 0 2px 20px var(--tabs-box-shadow);
  border-radius: 3px 3px 0 0;

  .ant-tabs-nav {
    margin: 0;
    padding: 0 16px;

    &::before {
      border-bottom: none;
    }

    .ant-tabs-nav-list {
      gap: 32px;
      padding-inline: 4px;

      .ant-tabs-tab {
        color: var(--plain-text);

        & + .ant-tabs-tab {
          margin: 0;
        }

        .ant-tabs-tab-btn {
          font-weight: var(--font-weight-medium);
          opacity: .6;
        }
      }
    }

    .ant-tabs-tab,
    .ant-tabs-tab.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        padding-inline: 18px;
        font-size: var(--font-size-title);

        &:hover {
          color: var(--tabs-hover-color);
        }
      }
    }

    .ant-tabs-tab-active::before {
      content: "";
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: var(--app-grayish);
      transform: scaleX(1.05);
      border-radius: 3px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: var(--app-grayish);
      font-weight: var(--font-weight-title);
      opacity: 1;
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  &.ant-tabs-top .ant-tabs-tab-active::before {
    bottom: 0;
  }

  &.ant-tabs-bottom .ant-tabs-tab-active::before {
    top: 0;
  }

  &.ant-tabs-right .ant-tabs-tab-active::before {
    left: 0;
  }

  &.ant-tabs-left .ant-tabs-tab-active::before {
    right: 0;
  }
}