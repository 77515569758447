@import '../../styles/colors';

.Dashboards {
  display: flex;
  flex-direction: column;
  height: 100%;

  .dashboards-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary-bg-color);
    box-shadow: 0 4px 12px var(--dahsboards-tabs-shadow-color);

    .dashboards-tabs.UiTabsNav {
      background-color: transparent;
      box-shadow: none;

      .ant-tabs-nav {
        margin: 0;
      }
    }

    .dashboards-controls {
      margin-right: 24px;
    }
  }
}