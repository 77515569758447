.impact-container {
  background: var(--app-white);
  height: 100%;
  display: flex;
  color: var(--app-purple);
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 3px;

  .business-impact-expandable {
    &, &::after {
      box-shadow: none;
    }

    .ui-title {
      text-transform: uppercase;
    }

    .expandable-content {
      max-height: 220px;
      background-color: var(--app-white);
      display: flex;
      padding: 12px 30px 32px 30px;
      justify-content: space-between;
      transition: 0.7s max-height, 0.4s padding;

      .col {
        flex: 1;
        padding: 0 12px;
        color: var(--plain-text);

        &:not(:last-of-type) {
          border-right: 1px solid var(--divider);
        }
      }

      .section-comments, .expandable-content-cols {

        .section-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .add-comment-button {
            display: flex;
            align-items: center;
            gap: 5px;
            cursor: pointer;

            &.disabled-btn {

              &:hover {
                cursor: not-allowed;
              }

              pointer-events: none;
            }

            .add-comment-btn {

              span {
                text-decoration: none;
                color: var(--risk-info-color);
              }
            }
          }
        }
      }

      .section-comments {
        width: 33%;

        .section-header {
          margin-bottom: 8px;
        }

        .section-content {
          overflow-y: auto;
        }
      }

      .expandable-content-cols {
        width: 66%;
        background: var(--grey-background-share-two);
        padding: 12px;
        display: flex;
        transition: 0.4s padding-block;
        margin-top: 30px;

        &.full-width {
          width: 100%;
        }
      }

      &.closed {
        max-height: 0;
        padding-block: 0;

        .expandable-content-cols {
          padding-block: 0;
        }
      }

      .col {
        overflow: hidden;

        &.comments {
          .section-content {
            overflow-y: scroll;
            height: 100%;
          }
        }
      }
    }

    .table-container {
      overflow-y: auto;

      .ant-table-wrapper.single-api .flex-column {
        max-width: 300px;
      }

      .ant-table-cell {
        max-width: unset;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .title {
      font-size: 11px;
    }

    .tags-wrapper {
      padding: 10px 0 15px 0;
      display: flex;
    }

    .ant-divider-vertical {
      height: 100%;
      background: var(--divider);
    }

    .description {
      grid-area: description;
    }

    .item-1 {
      grid-area: item-1;
    }

    .item-2 {
      grid-area: item-2;
    }

    .item-3 {
      grid-area: item-3;
    }


    td.ant-table-cell {
      padding: 8px 24px !important;
    }
  }
}
