@import '../../../../styles/fonts';

.ReleaseNotes {
  max-width: 1000px;
  margin: 0 auto;
  padding: 24px;

  .release-title {
    @include title3;
    font-weight: 500;
    padding: 8px;
    margin-top: 15px;
  }

  .headerrow-issuekey {
    width: 15%;
  }

  .headerrow-updated {
    width: 17%;
  }
}
