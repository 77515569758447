.UiWizard {

  .FormModal .ant-modal-content {

    .ant-modal-body .form-modal-body {
      box-shadow: none;
      padding: 0;
      background: transparent;
    }

    .ant-modal-footer .form-footer button {
      margin: 0;
    }
  }

  .wizard-steps {
    margin-bottom: 24px;
  }

  .ant-modal-content {
    padding: 20px;

    .ant-modal-header {
      background: transparent;
      min-height: 40px;
      padding: 0;

      .ant-modal-title {
        color: var(--plain-text);
      }
    }

    .ant-modal-footer {
      padding: 20px 0 0 0;
    }

    .ant-modal-body {
      margin: 0;
      padding: 0;

      .ant-form {
        border-radius: 5px;
        padding: 24px 0;
        background-color: var(--app-white);

        .ant-form-item {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}