.filterable-table {
    overflow: hidden;
    flex: 1;
    min-height: 40px;
    margin-top: 20px;
    .box-title {
        display: inline-block;
    }
    .title-row {
        display: flex;
        justify-content: space-between;
        min-height: 40px;
    }
    .ag-row-hover .hide-show-endpoint {
        display: initial;
    }
}
