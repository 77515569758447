@import '../../styles/fonts';

.user-container {
  box-sizing: border-box;

  .user-expandable-divider {
    margin: 0;
    border-color: var(--app-dark-background);
    border-width: 0;
    transition: border 0.2s 0.5s;

    &.show {
      border-color: var(--stopLight-gray);
      border-width: 1px;
      transition: border 0.2s;
    }
  }

  .content-container {
    position: relative;
    height: 100%;
    padding-top: 72px;
    flex: 1;
    z-index: 10;

    .timeline {
      position: relative;
      grid-area: timeline;
      height: 100%;
    }

    .master-detail {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 26px 24px 24px;
      overflow: hidden;
      grid-area: master-detail;

      .detail-header {
        position: sticky;
        z-index: 1;
        top: 0;
        margin-bottom: 10px;
      }

      .info-container {
        margin-bottom: 14px;

        &--call {
          overflow-y: auto;
        }
      }

      .user-table {
        flex: 1;
        overflow: hidden;

        .title-row .title {
          @include plain-text;
          font-weight: var(--font-weight-medium);
          padding-bottom: 10px;
        }
      }

      .info-message {
        margin: auto;
      }
    }
  }
}
