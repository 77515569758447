
.ant-tag.filter-tag {
  padding: 2px 10px;
  background-color: var(--light-grey);
  color: var(--plain-text);
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  border: 1px solid var(--border);
  align-items: center;
  margin-right: 12px;
  margin-bottom: 8px;
  font-size: var(--font-size-text);
  height: 28px;

  &.dotted{
    border: 1px dashed;
    background-color: var(--app-background);
  }

  .title-tag {
    text-transform: uppercase;
  }

  .anticon-close.ant-tag-close-icon {
    display: flex;
    align-items: center;
    color: var(--app-purple);
    height: 16px;

    svg {
      height: 100%;
    }
  }
}


