@import '../../styles/mixins';

.UiPopover {

  .ant-popover-inner {
    border: 1px solid var(--comment-border);
    box-shadow: 0px 4px 20px var(--container-box-shadow);
    border-radius: 3px;
  }
}

.UiPopoverOverlay {
  
  .ant-popover-title,
  .ant-popover-inner-content {  
    padding: 0;
  }
}
