@import '../../../../../styles/fonts';

.CollectorAddEditModal {

  .ant-modal-content .ant-modal-body {

    .ant-form {

      .node-general-form, .node-type-form {
        padding: 0 24px;

        > .ant-row:not(:last-child) {
          margin-block-end: 20px;
        }
      }

      .ant-divider {
        margin: 24px 0;
      }

      .additional-configuration {
        display: grid;
        min-height: 222px;
        padding: 0 24px;

        .rpm-additional-configuration {
          place-self: center;
          color: var(--plain-text);
        }

        .iso-additional-configuration > .ant-row {

          &:not(:last-child) {
            margin-block-end: 20px;
          }

          &:last-child .ant-form-item {
            margin-block-end: 0;
          }
        }
      }
    }

    .configuration-radio-toggle-container {
      label.ant-radio-wrapper {
        @include text-regular;
      }
    }
  }

  .readonly-text {
    @include plain-text;
  }

  .ant-btn + .ant-btn {
    margin-inline-start: var(--space-between-adjacent-buttons);
  }
}