.EditEndpointParameterNameModal {
  width: 100%;

  .is-multiple-endpoints-input {
    margin: 0;
  }

  .ant-select-selection-item {
    display: none;
  }

  .input-ready .ant-select-selection-item {
    display: flex;
  }
}
