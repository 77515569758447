@import '../../../../../styles/fonts';

.UpgradeCollectorModal {
  min-height: 420px;

  .changelog-md-fixer {
    margin-block-start: 12px;
    display: block;
  }

  .ucm-label {
    @include control-label;
  }

  .ucm-warning {
    font-size: var(--font-size-text);
    font-weight: var(--font-weight-medium);
    display: flex;
    align-items: center;
    gap: 5px;
    color: #FFA52B;
    margin-bottom: 16px;
  }

  .ucm-version-select {
    min-width: 220px;
  }

  .ucm-config-select {
    min-width: 300px;
  }

  .ucm-section {
    height: 240px;
    overflow: auto;
    border: 1px solid var(--app-purple-shade-two);
    padding: 8px 16px;
  }

  .ant-select-selection-item:after {
    content: unset !important;
  }
}
