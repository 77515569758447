.label-list-container {
  display: flex;
  gap: 4px 8px;
  flex-wrap: wrap;
  overflow: visible;
  overflow-y: auto;
  max-height: 95px;

  .label-item {
    display: flex;
    height: 24px;

    .ant-select-selector {
      min-height: 26px;
    }
  }

  &.stacked {
    display: block;

    & .label-item {
      margin: 8px 0;
    }
  }

  &.flex {
    display: flex;
    flex-wrap: wrap;
  }
}
