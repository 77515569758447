.ant-tag {
  color: var(--plain-text);
  border: 1px solid var(--plain-text);
  border-radius: 8px;
  padding: 1px 6px;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-sub);
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent;

  &.new-tag {
    border: 1px dashed var(--app-purple);
    color: var(--tag-color);
    background-color: var(--app-white);
  }

  .anticon-close, .anticon-close:hover {
    color: inherit;
  }
}

.ant-select.ant-select-auto-complete.add-tag {
  width: 110px;
  height: 24px;
  .ant-select-selector {
    height: 24px;
    border-radius: 8px;
    input {
      height: 24px;
    }
  }
}
