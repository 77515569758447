.UiTimeRangePicker {
  display: flex;
  gap: 10px;

  .trp-footer-label {
    color: var(--app-purple);
    font-size: var(--font-size-text);
    font-weight: var(--font-weight-light);
  }

  .trp-footer-select {
    width: 100px;
    height: 36px;
    font-size: var(--font-size-medium);
    font-weight: var(--font-weight-medium);
    color: var(--plain-text);
  }
}
