.alert-filter-form {

  .tab-input{
    margin-top: 5px;
  }

  .filter-tag-list {
    flex-direction: column;
    margin-top: 5px;
  }
}
