@import '../../styles/fonts.scss';

.AppsOrAuthConfigs {
  display: flex;
  flex-direction: column;
  height: 100%;

  .apit-main-page-header {
    display: flex;
    width: 100%;

    .apit-main-page-header-title {
      @include title;
      display: inline-block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }

  .apit-main-page-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 2rem;
    background-color: var(--app-white);
    box-shadow: 0 2px 20px var(--tabs-box-shadow);
    padding-inline-end: 20px;
    z-index: 1;

    .tabs-nav {
      flex-grow: 1;
      box-shadow: none;

      .ant-tabs-nav {
        padding: 0;
      }
    }
  }
}