@import '../../../../styles/fonts.scss';

.ApplicationDetails{
  display: flex;
  flex-direction: column;
  height: 100%;

  .app-details-header {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;

    .app-details-title {
      @include title;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      word-break: break-all;
    }
  }

  .app-details-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 16px;
    height: 100%;

    .subsections-command-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: var(--app-white);
      box-shadow: 0 2px 20px var(--tabs-box-shadow);
      padding-inline-end: 20px;
      z-index: 1;

      .tabs-nav {
        flex-grow: 1;
        box-shadow: none;

        .ant-tabs-nav {
          padding: 0;
        }
      }
    }

  }
}
