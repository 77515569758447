.home-widget {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .widget-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px;
    background-color: var(--app-white);
    border-bottom: 1px solid var(--app-purple-shade-one);

    .ui-title .x-large {
      font-weight: var(--font-weight-medium);
    }

    .text-button {
      text-decoration: underline;
      color: var(--app-purple);
    }
  }

  .content {
    border-radius: 3px;
    overflow: hidden;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--app-white);
  }
}
