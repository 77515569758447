@import '../../styles/mixins';
@import '../../styles/fonts';

.ExclusionRuleOrDeleteEndpointsWizard {

  .FormModal .ant-modal-content {

    .ant-modal-body .form-modal-body {
      box-shadow: none;
      padding: 0;
      background: transparent;

      .endpoint-filter-form-container {
        background: var(--app-white);
        margin-top: 24px;
        box-shadow: 0 4px 30px var(--container-box-shadow);

        .hidden {
          display: none;
        }

        .endpoint-filter-form-body {
          padding: 16px 24px 21px 24px;

          .endpoint-filter-modal-title {
            margin: 5px 0 18px 0;
            opacity: 0.8;
          }
        }

        .ant-radio-wrapper {
          min-width: 72px;
        }
      }
    }

    .ant-modal-footer .form-footer button {
      margin: 0;
    }
  }

  .er-wizard-title-container {
    display: flex;
    gap: 5px;
  }

  .er-wizard-subtitle {
    color: var(--body-text-80);
    font-weight: var(--font-weight-semibold);
  }
}
