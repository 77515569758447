.VerticalMenu {
    .ant-menu-vertical {
        .ant-menu-item {
            margin-top: 0;
            margin-bottom: 0;
            padding: 0 25px;
            height: 48px;
            display: flex;
            align-items: center;
            color: var(--app-white);

            &:hover {
                color: var(--app-purple);
                background-color: var(--app-purple-shade-four);
            }

            &.ant-menu-item-selected {
                box-shadow: inset 2px 0px 0px 0px var(--app-purple);
                color: var(--app-white);
                background-color: var(--app-purple-shade-four);
            }
        }
    }
}
