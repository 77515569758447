@import '../../../../../styles/colors.scss';
@import '../../../../../styles/fonts.scss';
@import '../findingDetailsContainer';

.FindingDetailsEvidence {
  @include findingDetailsContainer;
  flex-grow: 1;

  .finding-details-evidence-title {
    display: flex;
    align-items: center;

    .finding-details-evidence-title-scan-id {
      margin-inline-start: 10px;
      @include text-regular;
    }

    .finding-details-evidence-title-pagination-controls {
      flex-grow: 1;

      .UiPagination {
        justify-content: flex-end;
      }
    }
  }

  .finding-details-evidence-content {
    background-color: var(--app-white) !important;
  }

  .finding-details-evidence-parameters {
    display: flex;
    justify-content: space-between;
    padding-inline-end: 200px;
  }
}
