@import '../../../styles/fonts';

.ExclusionRuleOrDeleteEpForm {
  padding-inline: 24px;

  > .ant-form {

    .ant-checkbox-wrapper {
      @include control-label;
      margin-block-end: 16px;
    }

    .form-radios {
      margin-block-end: 20px;

      .ant-form-item-control-input {
        min-height: unset;
      }
    }

    .exclusion-rule-description {
      margin-top: 8px;
    }

    .ant-form-item-label label {
      margin-block-end: 8px;
    }

    > .ant-checkbox-wrapper-checked {
      margin-block-end: 10px;
    }
  }
}
