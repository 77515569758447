@import '~antd/dist/antd.css';
@import './styles/colors';
@import './styles/fonts';
@import './styles/mixins';
@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed&family=Saira+Extra+Condensed:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');

.box-title {
  color: var(--plain-text);
  font-size: var(--font-size-title);
  font-weight: var(--font-weight-medium);
  padding-bottom: 16px;
}

.control-label {
  @include control-label-inline;
}

.pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.box {
  background-color: var(--app-white);
  border-radius: 3px;
  flex: 1;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-message {
  font-size: 20px;
  color: var(--plain-text);
  opacity: 0.7;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  z-index: 1000;
}

::-webkit-scrollbar-thumb {
  background: #436ace99;
  border-radius: 20px;
}
