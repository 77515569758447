.pie-chart-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .content {
    display: flex;
    flex: 1 1 auto;
    padding: 24px 0;
  }

  .chart {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chart-legend {
    display: flex;
    flex: 0 0 50%;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}

.recharts-wrapper {
  text {
    text-transform: uppercase;
  }
}

.dark-chart .recharts-wrapper text {
  fill: var(--app-white);
}

.chart {
  text {
    font-family: 'Roboto';
  }

  .text-one {
    font-size: 16px;
    fill: var(--plain-text);
  }

  .text-two {
    font-size: 24px;
    fill: var(--plain-text);
    font-weight: semibold;
  }
}
