@import '../../../../../styles/fonts';

.ApplicationSummary{
  background: var(--lighter-grey);
  margin-bottom: 16px;
  padding: 16px;
  display: grid;
  grid-auto-flow: column;

  .dividerCell{
    display: flex;
    justify-content: space-between;
  }
}
