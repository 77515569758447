.alerts-overview-container {
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: var(--app-white);
  overflow: auto;
  color: var(--plain-text);
  padding: 0 14px;

  .alerts-header-row {
    display: flex;
    height: 85px;
    align-items: center;
    border-bottom: 1px solid var(--app-purple-shade-one);

    .spacer {
      width: 150px;
    }

    .headers {
      display: flex;
      flex: 1;
      align-items: flex-end;
      justify-content: space-between;
      padding: 12px 0;

      .header {
        display: flex;
        flex-direction: column;
        flex: 1 1 20%;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .top {
          display: flex;
          justify-content: center;
          position: relative;
          align-items: center;
          margin-bottom: 8px;
        }
      }

      .bottom {
        display: flex;

        .alert-severity {
          padding-right: 0.1rem;
          font-size: var(--font-size-small);
          letter-spacing: -0.5px;
          text-transform: uppercase;
          color: var(--app-grayish);
          font-weight: var(--font-weight-medium);
        }

        .count {
          font-size: 14px;
          letter-spacing: -1px;
          color: var(--app-purple);
        }
      }
    }
  }

  .custom-grid {
    height: calc(100% - 80px);
    padding-bottom: 12px;

    .alerts-row {
      display: flex;
      padding: 12px 10px;
      border-bottom: 1px solid var(--app-purple-shade-one);

      .tag-label {
        width: 150px;
        display: flex;
        align-items: center;
      }
      .alerts-values {
        flex: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .empty {
          height: 28px;
          width: 28px;
        }

        .bubble {
          height: 28px;
          width: 28px;
          display: flex;
          font-weight: 500;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
          background-color: var(--settings-bg);

          &.info {
            color: var(--info-blue);
          }

          &.low {
            color: var(--app-purple);
          }

          &.medium {
            color: var(--yellow);
          }

          &.high {
            color: var(--red);
          }

          &.critical {
            color: var(--critical-red);
          }
        }
      }
    }
  }
}
